import axios from 'axios';
import { BaseService } from './BaseService';

export class ProductService extends BaseService {

    async getProducts(offset = 0, skip = 0, limit = 10, sortField = 'id', sortOrder = 1, filters) {
        let newFilter = this.getFilters(filters);

        let parameters = {
            "offset": offset,
            "limit": limit,
            "skip": skip,
            "order": `${sortField} ${sortOrder == 1 ? 'asc' : 'desc'}`,
          };

          if (newFilter) {
            parameters["where"] = newFilter;
          }
          let res = await this.get(`/products?filter=${encodeURI(JSON.stringify(parameters))}`, true);
        return {
            data: res,
            page: skip
        }
    }

    async getCountProducts(filters) {
        let newFilter = this.getFilters(filters);
        let parameters = null;
        let strWhere = ''

        if (newFilter) {
            parameters = JSON.stringify({...newFilter});
        }

        if (parameters) {
            strWhere += `?where=${encodeURI(parameters)}`;
        }
        return this.get(`/products/count${strWhere}`, true).then(res => res.count);
    }

    getFilters(filters = {}) {
        let newFilter = null;

        const fromTo = {
            'contains': 'like',
            'equals': 'eq'
        };

        if (filters) {
            for (const [key, value] of Object.entries(filters)) {
                console.log(`${key}:`, value);
                if (value.constraints[0].value != null) {
                    let newValue = {};

                    let operator = fromTo[value.constraints[0].matchMode];

                    if (operator == 'like') {
                        newValue[operator] = `%${value.constraints[0].value}%`;
                    } else {
                        newValue[operator] = value.constraints[0].value;
                    }

                    if (newFilter == null) {
                        newFilter = {}
                    }

                    newFilter[key] = newValue;
                }
            }
        }
        return newFilter;
    }

    async deleteProduct(id) {
        return await this.delete(`/products/${id}`, true);
    }

    async editProduct(product) {
        const data = { code: product.code,  name: product.name, description: product.description, residenceId: product.residenceId };
        return await this.patch(`/products/${product.id}`, data, true);
    }

    async createProduct(product) {
        const data = { code: product.code,  name: product.name, description: product.description, residenceId: product.residenceId };
        return await this.post(`/products`, data, true);
    }

    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
    }

    getProductsMock() {
        return axios.get('assets/demo/data/products.json').then(res => res.data.data);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
    }
}