import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { 
    disableUserAsync, 
    sendWelcomeEmailAsync, 
    sendPasswordEmailAsync,
    setIsActiveSubscriptionAsync,
    setActiveDeviceAsync,
    selectLoading, 
    selectUserList, 
    selectUserListCount, 
    getUsersCountAsync,
    getUsersAsync, 
} from './userSlice';

const UsersView = () => {
    const defaultGridParameters = {page: 0, first: 0, rows: 10, sortField: 'id', sortOrder: 1, filters: {}}

    const [displayDisableDialog, setDisplayDisableDialog] = useState(false);
    const [displayWelcomeEmailDialog, setDisplayWelcomeEmailDialog] = useState(false);
    const [displayPasswordEmailDialog, setDisplayPasswordEmailDialog] = useState(false);
    const [displayDetailDialog, setDisplayDetailDialog] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [gridParameters, setGridParameters] = useState(defaultGridParameters);
    const [expandedRows, setExpandedRows] = useState(null);
    const [detail, setDetail] = useState({});

    const toast = useRef(null);
    const menu = useRef(null);
    const dt = useRef(null);

    const dispatch = useDispatch();
    const userList = useSelector(selectUserList);
    const userListCount = useSelector(selectUserListCount);
    const loading = useSelector(selectLoading);

    const statuses = [
        'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
    ];

    const toggleMenu = (event, data) => {
        setDetail(data)
        menu.current.toggle(event);
    };

    const toolbarItems = [
        // {
        //     label: 'Desabilitar',
        //     icon: 'pi pi-times',
        //     command: () => setDisplayDisableDialog(true)
        // },
        {
            label: 'Email boas vindas',
            icon: 'pi pi-send',
            command: () => setDisplayWelcomeEmailDialog(true),
            disabled: detail.isVerifiedAccount
        },
        {
            label: 'Email senha',
            icon: 'pi pi-send',
            command: () => setDisplayPasswordEmailDialog(true)
        },
        {
            label: 'Visualizar',
            icon: 'pi pi-eye',
            command: () => setDisplayDetailDialog(true)
        },
    ];

    useEffect(() => {
        dispatch(getUsersAsync(defaultGridParameters))
        dispatch(getUsersCountAsync(defaultGridParameters))
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formatDate = (value) => {
        return value.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }

    const confirmDisable = () => {
        setDisplayDisableDialog(false)
        dispatch(disableUserAsync(1))
        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Usuário desabilitado', life: 3000 });
    };

    const cancelDisable = () => {
        setDisplayDisableDialog(false)
    };

    const disableDialogFooter = (
        <>
            <Button type="button" label="Não" icon="pi pi-times" onClick={cancelDisable} className="p-button-text" />
            <Button type="button" label="Sim" icon="pi pi-check" onClick={confirmDisable} className="p-button-text" autoFocus />
        </>
    );

    const confirmWelcomeEmail = () => {
        setDisplayWelcomeEmailDialog(false)
        dispatch(sendWelcomeEmailAsync(detail.email))
        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Email enviado', life: 3000 });
    };

    const cancelWelcomeEmail = () => {
        setDisplayWelcomeEmailDialog(false)
    };

    const welcomeEmailDialogFooter = (
        <>
            <Button type="button" label="Não" icon="pi pi-times" onClick={cancelWelcomeEmail} className="p-button-text" />
            <Button type="button" label="Sim" icon="pi pi-check" onClick={confirmWelcomeEmail} className="p-button-text" autoFocus />
        </>
    );

    const confirmPasswordEmail = () => {
        setDisplayPasswordEmailDialog(false)
        dispatch(sendPasswordEmailAsync(detail.email))
        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Email enviado', life: 3000 });
    };

    const cancelPasswordEmail = () => {
        setDisplayPasswordEmailDialog(false)
    };

    const passwordEmailDialogFooter = (
        <>
            <Button type="button" label="Não" icon="pi pi-times" onClick={cancelPasswordEmail} className="p-button-text" />
            <Button type="button" label="Sim" icon="pi pi-check" onClick={confirmPasswordEmail} className="p-button-text" autoFocus />
        </>
    );

    const hideDetailDialog = () => {
        setDisplayDetailDialog(false)
    }

    const detailDialogFooter = (
        <>
            <Button label="Fechar" icon="pi pi-times" className="p-button-text" onClick={hideDetailDialog} />
        </>
    );

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />
    }

    
    const statusBodyTemplate = (rowData) => {
        return <span className={`customer-badge status-${rowData.status}`}>{rowData.status}</span>;
    }

    const actionBodyTemplate = (rowData) => {
        return <>
            <Menu ref={menu} model={toolbarItems} popup />
            <Button type="button" label="Ações" icon="pi pi-angle-down" onClick={(event) => toggleMenu(event, rowData)} style={{ width: 'auto' }} />
        </>
    }

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const verifiedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.isVerifiedAccount, 'text-pink-500 pi-times-circle': !rowData.isVerifiedAccount })}></i>;
    }

    const verifiedFilterTemplate = (options) => {
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />
    }

    const initialLoadDataGrid = () => {
        dispatch(getUsersCountAsync(defaultGridParameters))
        dispatch(getUsersAsync(defaultGridParameters))
    }

    const loadDataGrid = () => {
        dispatch(getUsersCountAsync({filters: {}}))
        dispatch(getUsersAsync({page: 0, first: 0, rows: 10}))
    }
    
    const onSort = (e) => {
        let filters = {...gridParameters.filters};
        const parameters = {
            ...e,
            page: 0,
            first: 0,
            filters
        };
        setGridParameters(parameters)
        dispatch(getUsersAsync(parameters))
        dispatch(getUsersCountAsync(parameters))
    }

    const onFilter = (e) => {
        // console.log('onFilter >>>> ', e)
        let filters = {...e.filters};
        const parameters = {
            ...e,
            page: 0,
            first: 0,
            filters
        };

        // console.log('filters >>>> ', filters)
        setGridParameters(parameters)
        dispatch(getUsersAsync(parameters))
        dispatch(getUsersCountAsync(parameters))
    }
    
    const onPage = (e) => {
        let filters = {...gridParameters.filters};
        const parameters = {
            ...e,
            filters
        };
        setGridParameters(parameters)
        dispatch(getUsersAsync(parameters))
    }

    const onChangeIsActiveSubscriptionCheckbox = (id, isActive) => {
        dispatch(setIsActiveSubscriptionAsync({id, isActive, gridParameters}));
        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: `Assinatura ${isActive ? 'ativada' : 'desativada'}`, life: 3000 });
    }

    const onChangeActiveDeviceCheckbox = (userId, deviceId, active) => {
        dispatch(setActiveDeviceAsync({userId, deviceId, active, gridParameters}));
        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: `Dispositivo ${active ? 'ativado' : 'desativado'}`, life: 3000 });
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const isActiveBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ativa</span>
                {rowData.is_active ? 'Sim' : 'Não'}
                {/* <span className={`choice-badge status-${rowData.isCorrect}`}>{rowData.inventoryStatus}</span> */}
            </>
        )
    }

    const activeDeviceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ativa</span>
                {rowData.active ? 'Sim' : 'Não'}
                {/* <span className={`choice-badge status-${rowData.isCorrect}`}>{rowData.inventoryStatus}</span> */}
            </>
        )
    }

    const actionBodyTemplateSubscriptions = (rowData) => {
        return (
            <div className="actions">
                <Checkbox name="is_active" value={rowData.is_active} onChange={(e) => onChangeIsActiveSubscriptionCheckbox(rowData.id, !e.value)} checked={rowData.is_active} />
            </div>
        );
    }

    const actionBodyTemplateDevices = (rowData, userId) => {
        return (
            <div className="actions">
                <Checkbox name="active" value={rowData.active} onChange={(e) => onChangeActiveDeviceCheckbox(userId, rowData.id, !e.value)} checked={rowData.active} />
            </div>
        );
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Assinaturas:</h5>
                <DataTable value={data.subscriptions} responsiveLayout="scroll">
                    <Column field="start_on" header="Início" sortable></Column>
                    <Column field="end_on" header="Fim" sortable></Column>
                    <Column field="is_active" header="Ativa" sortable body={isActiveBodyTemplate}></Column>
                  <Column body={actionBodyTemplateSubscriptions} style={{ width: '15%', minWidth: '10rem' }}></Column> 
                </DataTable>
                <h5>Dispositivos:</h5>
                <DataTable value={data.devices} responsiveLayout="scroll">
                    <Column field="createdAt" header="Registrado em" sortable></Column>
                    <Column field="identifier" header="Identificador" sortable></Column>
                    <Column field="active" header="Ativo" sortable body={activeDeviceBodyTemplate}></Column>
                  <Column body={(rowData) => actionBodyTemplateDevices(rowData, data.id)} style={{ width: '15%', minWidth: '10rem' }}></Column> 
                </DataTable>
            </div>
        );
      }

    return (
        <>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        {/* <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

                        <DataTable ref={dt} value={userList} selection={selectedUsers} onSelectionChange={(e) => setSelectedUsers(e.value)}
                            dataKey="id" 
                            paginator 
                            sortField={gridParameters.sortField}
                            sortOrder={gridParameters.sortOrder}
                            rows={gridParameters.rows} 
                            rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
                            emptyMessage="Sem resultados." 
                            responsiveLayout="scroll"
                            onRowToggle={(e) => setExpandedRows(e.data)} 
                            rowExpansionTemplate={rowExpansionTemplate}
                            expandedRows={expandedRows} 
                            totalRecords={userListCount} 
                            loading={loading}
                            lazy={true}
                            first={gridParameters.first}
                            onSort={(e) => onSort(e)}
                            onFilter={(e) => onFilter(e)}
                            onPage={(e) => onPage(e)}
                            >
                            <Column expander style={{ width: '3em' }} />
                            {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                            {/* <Column field="id" header="ID" sortable body={idBodyTemplate} headerStyle={{ width: '5%', minWidth: '5rem' }}></Column> */}
                            <Column field="id" hidden></Column>
                            <Column field="isVerifiedAccount" hidden></Column>
                            <Column field="name" header="Nome" sortable  filterPlaceholder="Buscar por nome" style={{ minWidth: '12rem' }} />
                            <Column field="email" header="Email" sortable filter filterPlaceholder="Buscar por email" style={{ minWidth: '12rem' }} showAddButton={false} showFilterOperator={false} filterMatchMode={FilterMatchMode.CONTAINS} filterMatchModeOptions={[FilterMatchMode.CONTAINS]}/>

                            <Column header="Data Cadastro" filterField="date" dataType="date" style={{ minWidth: '10rem' }} body={dateBodyTemplate}
                                 filterElement={dateFilterTemplate} />

                            <Column field="residence" header="Residências" style={{ minWidth: '10rem' }} />

                            {/* <Column field="isVerifiedAccount" header="Verificado" sortable dataType="boolean" bodyClassName="text-center" style={{ minWidth: '8rem' }} body={verifiedBodyTemplate} filter filterElement={verifiedFilterTemplate} /> */}

                            {/* <Column field="status" header="Status" filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} body={statusBodyTemplate} filter filterElement={statusFilterTemplate} /> */}

                            <Column body={actionBodyTemplate} style={{ width: '15%', minWidth: '10rem' }}></Column>
                        </DataTable>
                    </div>
                </div>
                <Dialog header="Sucesso" visible={displayDisableDialog} onHide={() => setDisplayDisableDialog(false)} style={{ width: '350px' }} modal footer={disableDialogFooter}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        <span>Tem certeza de que deseja continuar?</span>
                    </div>
                </Dialog>
                <Dialog header="Sucesso" visible={displayWelcomeEmailDialog} onHide={() => setDisplayWelcomeEmailDialog(false)} style={{ width: '350px' }} modal footer={welcomeEmailDialogFooter}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        <span>Tem certeza de que deseja continuar?</span>
                    </div>
                </Dialog>
                <Dialog header="Sucesso" visible={displayPasswordEmailDialog} onHide={() => setDisplayPasswordEmailDialog(false)} style={{ width: '350px' }} modal footer={passwordEmailDialogFooter}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        <span>Tem certeza de que deseja continuar?</span>
                    </div>
                </Dialog>
                <Dialog visible={displayDetailDialog} style={{ width: '450px' }} header="Usuário" modal className="p-fluid" footer={detailDialogFooter} onHide={hideDetailDialog}>
                    <div className="field">
                        <label htmlFor="name">Nome</label>
                        <InputText value={detail.name} disabled />
                    </div>
                    <div className="field">
                        <label htmlFor="name">Email</label>
                        <InputText value={detail.email} disabled />
                    </div>
                    <div className="field">
                        <label htmlFor="name">Data de Cadastro</label>
                        <InputText value={formatDate(new Date(detail.date))} disabled />
                    </div>
                    <div className="orders-subtable">
                        <DataTable value={detail.residences} responsiveLayout="scroll">
                            <Column field="description" header="Residências" sortable></Column>
                        </DataTable>
                    </div>
                </Dialog>

            </div>
        </>        
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UsersView, comparisonFn);
