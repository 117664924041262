import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { AuthenticatedLink } from '../../components/AuthenticateLink';

import { 
    selectLoading, 
    selectImportationList, 
    selectImportationListCount, 
    getImportationsAsync, 
    getImportationsCountAsync, 
    downloadImportationFileAsync,
    importationStarted,
    importationFinished,
} from './importationSlice';

const ImportationsView = () => {

    const defaultGridParameters = {page: 0, first: 0, rows: 10, sortField: 'id', sortOrder: 1, filters: {}}
    
    const [selectedImportations, setSelectedImportations] = useState(null);

    const [gridParameters, setGridParameters] = useState(defaultGridParameters);

    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);
    const importationList = useSelector(selectImportationList);
    const importationListCount = useSelector(selectImportationListCount);

    useEffect(() => {
        initialLoadDataGrid();
    }, []);

    const initialLoadDataGrid = () => {
        dispatch(getImportationsCountAsync(defaultGridParameters))
        dispatch(getImportationsAsync(defaultGridParameters))
    }

    const loadDataGrid = () => {
        dispatch(getImportationsCountAsync({filters: {}}))
        dispatch(getImportationsAsync({page: 0, first: 0, rows: 10}))
    }

    const loadDataGridKeepingParameters = () => {
        dispatch(getImportationsCountAsync({filters: gridParameters.filters}))
        dispatch(getImportationsAsync(gridParameters))
    }
    
    const onSort = (e) => {
        let filters = {...gridParameters.filters};
        const parameters = {
            ...e,
            page: 0,
            first: 0,
            filters
        };
        setGridParameters(parameters)
        dispatch(getImportationsAsync(parameters))
        dispatch(getImportationsCountAsync(parameters))
    }

    const onFilter = (e) => {
        let filters = {...gridParameters.filters};
        const parameters = {
            ...e,
            page: 0,
            first: 0,
            filters
        };
        setGridParameters(parameters)
        dispatch(getImportationsAsync(parameters))
        dispatch(getImportationsCountAsync(parameters))
    }
    
    const onPage = (e) => {
        let filters = {...gridParameters.filters};
        const parameters = {
            ...e,
            filters
        };
        setGridParameters(parameters)
        dispatch(getImportationsAsync(parameters))
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <Button label="Excluir" icon="pi pi-trash" className="mr-2 p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedImportations || !selectedImportations.length} /> */}
                <FileUpload mode="basic" accept="xlsx/*" maxFileSize={1000000} label="Importar" chooseLabel="Importar" className="mr-2 inline-block" 
                uploadHandler={(e) => console.log('uploadHandler', e)} 
                onBeforeSend={(e) => {
                    e.xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem(process.env.REACT_APP_TOKEN_KEY)}`);
                    console.log('onBeforeSend', e)
                    dispatch(importationStarted())
                }} 
                onBeforeUpload={(e) => {
                    toast.current.show({ severity: 'info', summary: 'Informação', detail: 'Arquivo enviado', life: 3000 });
                }}
                onUpload={(e) => {
                    console.log('onUpload', e)
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Arquivo de importação processado', life: 5000 });
                    dispatch(importationFinished({gridParameters}))
                }} 
                onError={(e) => {
                    console.log('onError', e)
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Falha na importação', life: 3000 });
                    dispatch(importationFinished({gridParameters}))
                }}
                onProgress={(e) => {
                    console.log('onProgress', e)
                    toast.current.show({ severity: 'info', summary: 'Informação', detail: 'Importação em progresso', life: 5000 });
                }}
                url={`${process.env.REACT_APP_API_URL}/importations/files`}
                />
                {/* <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} /> */}
            </React.Fragment>
        )
    }

    const createdAtBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Data</span>
                {rowData.createdAt}
            </>
        );
    }

    const fileNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Arquivo</span>
                {rowData.fileName}
            </>
        );
    }

    const testBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prova</span>
                {rowData.test.title}
            </>
        );
    }

    const fileSizeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tamanho</span>
                {rowData.fileSize}
            </>
        );
    }

    const hasErrorsBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Erros?</span>
                {rowData.hasErrors ? 'Sim' : 'Não'}
            </>
        );
    }

    const rowExpansionTemplate = (data) => {
      return (
          <div className="orders-subtable">
              <h5>Erros:</h5>
              <DataTable value={data.errorList} responsiveLayout="scroll" emptyMessage='Nenhum erro identificado.'>
                <Column field="description" header="Descrição" sortable></Column>
              </DataTable>
          </div>
      );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <AuthenticatedLink url={`${process.env.REACT_APP_API_URL}/importations/download/${rowData.id}`} filename={rowData.fileName} >
                <Button icon="pi pi-download" tooltip='Download XLSX' className="p-button-rounded p-button-primary mr-2"/>
            </AuthenticatedLink>
        );
    }

    return (
      <>
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                    <DataTable ref={dt} value={importationList} selection={selectedImportations} onSelectionChange={(e) => setSelectedImportations(e.value)}
                        dataKey="id" 
                        paginator 
                        sortField={gridParameters.sortField}
                        sortOrder={gridParameters.sortOrder}
                        rows={gridParameters.rows} 
                        rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
                        emptyMessage="Sem resultados." 
                        responsiveLayout="scroll"
                        rowExpansionTemplate={rowExpansionTemplate}
                        expandedRows={expandedRows} 
                        onRowToggle={(e) => setExpandedRows(e.data)} 
                        totalRecords={importationListCount} 
                        loading={loading}
                        lazy={true}
                        first={gridParameters.first}
                        onSort={(e) => onSort(e)}
                        onFilter={(e) => onFilter(e)}
                        onPage={(e) => onPage(e)}
                        >
                        <Column expander style={{ width: '1em' }} />
                        <Column field="createdAt" header="Data" sortable body={createdAtBodyTemplate} headerStyle={{ width: '1em', minWidth: '5rem' }}></Column>
                        <Column field="fileName" header="Arquivo" sortable body={fileNameBodyTemplate} headerStyle={{ width: '1em', minWidth: '5rem' }}></Column>
                        <Column field="hasErrors" header="Erros?" body={hasErrorsBodyTemplate} headerStyle={{ width: '1em', minWidth: '5rem' }}></Column>
                        <Column body={actionBodyTemplate} style={{ width: '15%', minWidth: '10rem' }}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
      </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ImportationsView, comparisonFn);