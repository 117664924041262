import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { signin } from './signInAPI';
import { AuthService } from '../../service/AuthService';
import { useHistory } from 'react-router-dom';

const authService = new AuthService();

const initialState = {
  user: JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_KEY)),
  status: 'idle',
  message: null
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const signinAsync = createAsyncThunk(
  'signin',
  async ({email, password}) => {
    return await authService.signin(email, password);
  }
  // async () => {
  //   const response = await signin();
  //   // The value we return becomes the `fulfilled` action payload
  //   return response;
  // }
);

export const signinSlice = createSlice({
  name: 'signinSlice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: (state) => {
      localStorage.removeItem(process.env.REACT_APP_USER_KEY);
      localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
      state.user = null;
    },
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(signinAsync.pending, (state) => {
        state.message = null;
        state.status = 'loading';
      })
      .addCase(signinAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.message = 'Usuário e/ou senha inválidos.'
      })
      .addCase(signinAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        if (!action.payload) {
          state.message = 'Usuário e/ou senha inválidos.'
        } else {
          state.message = null;
          const user = {
            displayName: action.payload.displayName,
            email: action.payload.email
          };
          localStorage.setItem(process.env.REACT_APP_USER_KEY, JSON.stringify(user));
          localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, action.payload.stsTokenManager.accessToken);
          state.user = action.payload;
        }
      });
  },
});

// export const { increment, decrement, incrementByAmount } = signinSlice.actions;
export const { logout } = signinSlice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state) => state.counter.value;

export const selectMessage = (state) => state.signin.message;
export const selectUser = (state) => state.signin.user;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default signinSlice.reducer;