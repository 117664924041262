import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { 
    selectLoading, 
    selectResidenceList,
    selectModuleList, 
    selectModuleListCount, 
    getModulesAsync, 
    getModulesCountAsync, 
    deleteModuleAsync, 
    editModuleAsync,
    createModuleAsync,
    setModuleResidenceAsync,
    initialLoadAsync
} from './moduleSlice';

const ModuleView = () => {

    let emptyModule = {
        id: null,
        description: "",
        weightPercentage: 0
    }

    const defaultGridParameters = {page: 0, first: 0, rows: 10, sortField: 'id', sortOrder: 1, filters: {}}
    
    const [deleteModuleDialog, setDeleteModuleDialog] = useState(false);
    
    const [moduleDialog, setModuleDialog] = useState(false);
    const [module, setModule] = useState(emptyModule);
    const [selectedModules, setSelectedModules] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);

    const [gridParameters, setGridParameters] = useState(defaultGridParameters);

    const toast = useRef(null);
    const dt = useRef(null);

    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);
    const residenceList = useSelector(selectResidenceList);
    const moduleList = useSelector(selectModuleList);
    const moduleListCount = useSelector(selectModuleListCount);

    useEffect(() => {
        dispatch(initialLoadAsync(defaultGridParameters))
    }, []);
    
    const onSort = (e) => {
        let filters = {...gridParameters.filters};
        const parameters = {
            ...e,
            page: 0,
            first: 0,
            filters
        };
        setGridParameters(parameters)
        dispatch(getModulesAsync(parameters))
        dispatch(getModulesCountAsync(parameters))
    }

    const onFilter = (e) => {
        let filters = {...gridParameters.filters};
        const parameters = {
            ...e,
            page: 0,
            first: 0,
            filters
        };
        setGridParameters(parameters)
        dispatch(getModulesAsync(parameters))
        dispatch(getModulesCountAsync(parameters))
    }
    
    const onPage = (e) => {
        console.log('onPage e', e)
        let filters = {...gridParameters.filters};
        const parameters = {
            ...e,
            filters
        };
        setGridParameters(parameters)
        dispatch(getModulesAsync(parameters))
    }

    const openNew = () => {
        setModule(emptyModule);
        setModuleDialog(true);
    }

    const hideModuleDialog = () => {
        setModuleDialog(false);
    }

    const hideDeleteModuleDialog = () => {
        setDeleteModuleDialog(false);
    }

    const saveModule = () => {
        if (module.id) {
            dispatch(editModuleAsync({module, gridParameters}));
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Módulo atualizado', life: 3000 });
        } else {
            dispatch(createModuleAsync({module, gridParameters}));
            setGridParameters({...gridParameters, page: 0, first: 0});
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Módulo criado', life: 3000 });
        }
        setModuleDialog(false);
        setModule(emptyModule);
    }

    const editModule = (module) => {
        setModule({ ...module });
        setModuleDialog(true);
    }

    const confirmDeleteModule = (module) => {
        setModule(module);
        setDeleteModuleDialog(true);
    }

    const deleteModule = () => {
        setDeleteModuleDialog(false);
        setModule(emptyModule);
        dispatch(deleteModuleAsync({id: module.id, gridParameters}));
        setGridParameters({...gridParameters, page: 0, first: 0});
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Quesão excluída', life: 3000 });
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        // setDeleteModulesDialog(true);
    }

    const onInputChangeModule = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _module = { ...module };
        _module[`${name}`] = val;
        setModule(_module);
    }

    const onInputNumberChangeModule = (e, name) => {
        console.log('onInputNumberChangeModule', e)
        const val = e.value;
        let _module = { ...module };
        _module[`${name}`] = val;
        console.log('onInputChangeModule module', _module)
        setModule(_module);
    }

    const rowExpansionTemplate = (data) => {
        console.log('rowExpansionTemplate data', data)
        return (
            <div className="orders-subtable">
                <h5>Residências:</h5>
                <DataTable value={residenceList} responsiveLayout="scroll">
                    <Column field="id" header="ID" sortable style={{ width: '1em' }}></Column>
                    <Column field="description" header="Nome" sortable style={{ width: '1em' }}></Column>
                    <Column body={(rowData) => actionBodyTemplateResidences(rowData, data)} style={{ width: '1em', minWidth: '10rem' }}></Column> 
                </DataTable>
            </div>
        );
    }

    const actionBodyTemplateResidences = (rowData, data) => {
        console.log('actionBodyTemplateResidences rowData', rowData)
        console.log('actionBodyTemplateResidences data', data)
        return (
            <div className="actions">
                <Checkbox name="is_active" value={rowData.id} onChange={(e) => onChangeResidenceCheckbox(data.id, rowData.id)} checked={data.residences.filter(it => it.id == rowData.id).length > 0} />
            </div>
        );
    }

    const onChangeResidenceCheckbox = (moduleId, residenceId) => {
        dispatch(setModuleResidenceAsync({moduleId, residenceId, gridParameters}));
        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: `Operação realizada.`, life: 3000 });
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Novo módulo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editModule(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteModule(rowData)} />
            </div>
        );
    }

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.id}
            </>
        );
    }

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">UF</span>
                {rowData.description}
            </>
        );
    }

    const percentageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Peso(%)</span>
                {rowData.weightPercentage}
            </>
        );
    }

    const moduleDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideModuleDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveModule} />
        </>
    );

    const deleteModuleDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteModuleDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteModule} />
        </>
    );

    return (
      <>
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt} value={moduleList} selection={selectedModules} onSelectionChange={(e) => setSelectedModules(e.value)}
                        dataKey="id" 
                        paginator 
                        sortField={gridParameters.sortField}
                        sortOrder={gridParameters.sortOrder}
                        rows={gridParameters.rows} 
                        rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
                        emptyMessage="Sem resultados." 
                        responsiveLayout="scroll"
                        onRowToggle={(e) => setExpandedRows(e.data)} 
                        rowExpansionTemplate={rowExpansionTemplate}
                        expandedRows={expandedRows}
                        totalRecords={moduleListCount} 
                        loading={loading}
                        lazy={true}
                        first={gridParameters.first}
                        onSort={(e) => onSort(e)}
                        onFilter={(e) => onFilter(e)}
                        onPage={(e) => onPage(e)}
                        >
                        <Column expander style={{ width: '1em' }} />
                        <Column field="id" header="ID" sortable body={idBodyTemplate} headerStyle={{ width: '1em', minWidth: '5rem' }}></Column>
                        <Column field="description" header="Nome" filterPlaceholder="Buscar por nome" showAddButton={false} showFilterOperator={false} filterMatchMode={FilterMatchMode.EQUALS} filterMatchModeOptions={[FilterMatchMode.EQUALS]} sortable body={descriptionBodyTemplate} headerStyle={{ width: '2em', minWidth: '10rem' }}></Column>
                        <Column field="weightPercentage" header="Peso(%)" sortable body={percentageBodyTemplate} headerStyle={{ width: '1em', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate} style={{ width: '1em', minWidth: '10rem' }}></Column>
                    </DataTable>

                    {/* Editar Módulo */}
                    <Dialog visible={moduleDialog} style={{ width: '450px' }} header="Editar módulo" modal className="p-fluid" footer={moduleDialogFooter} onHide={hideModuleDialog}>
                        <h5>{module.title}</h5>

                        <div className="field">
                            <label htmlFor="description">Nome</label>
                            <InputText id="description" value={module.description} onChange={(e) => onInputChangeModule(e, 'description')} required />
                        </div>

                        <div className="field">
                            <label htmlFor="weightPercentage">Peso(%)</label>
                            <InputNumber id="weightPercentage" min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={20} value={module.weightPercentage} onChange={(e) => onInputNumberChangeModule(e, 'weightPercentage')} required />
                        </div>
                    </Dialog>
                    {/* FIM - Editar Módulo */}

                    <Dialog visible={deleteModuleDialog} style={{ width: '450px' }} header="Confirmar exclusão" modal footer={deleteModuleDialogFooter} onHide={hideDeleteModuleDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {module && <span>Você deseja realmente excluir: <b>{module.description}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
      </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ModuleView, comparisonFn);