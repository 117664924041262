import { createSlice } from '@reduxjs/toolkit';
// import { Toast } from 'primereact/toast';

const initialState = {
  message: null
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addMessage: (state, action) => {
      const message = action.payload;

      console.log(message)

      // const toast = new Toast();
      // toast.show({ severity: 'error', summary: 'Error Message', detail: 'Message Detail', life: 3000 })
      state.message = message
    }    
  },
});

export const { addMessage } = appSlice.actions;

export const selectMessage = (state) => state.app.message;

export default appSlice.reducer;