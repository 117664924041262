import axios from 'axios';
import { BaseService } from './BaseService';

export class MedicalServiceService extends BaseService {

    async getMedicalServices(offset = 0, skip = 0, limit = 10, sortField = 'id', sortOrder = 1, filters) {
        let newFilter = this.getFilters(filters);

        let parameters = {
            "offset": offset,
            "limit": limit,
            "skip": skip,
            "order": `${sortField} ${sortOrder == 1 ? 'asc' : 'desc'}`,
          };

          if (newFilter) {
            parameters["where"] = newFilter;
          }
          let res = await this.get(`/medical-services?filter=${encodeURI(JSON.stringify(parameters))}`, true);
        return {
            data: res,
            page: skip
        }
    }

    async getCountMedicalServices(filters) {
        let newFilter = this.getFilters(filters);
        let parameters = null;
        let strWhere = ''

        if (newFilter) {
            parameters = JSON.stringify({...newFilter});
        }

        if (parameters) {
            strWhere += `?where=${encodeURI(parameters)}`;
        }
        return this.get(`/medical-services/count${strWhere}`, true).then(res => res.count);
    }

    getFilters(filters = {}) {
        let newFilter = null;

        const fromTo = {
            'contains': 'like',
            'equals': 'eq'
        };

        if (filters) {
            for (const [key, value] of Object.entries(filters)) {
                console.log(`${key}:`, value);
                if (value.constraints[0].value != null) {
                    let newValue = {};

                    let operator = fromTo[value.constraints[0].matchMode];

                    if (operator == 'like') {
                        newValue[operator] = `%${value.constraints[0].value}%`;
                    } else {
                        newValue[operator] = value.constraints[0].value;
                    }

                    if (newFilter == null) {
                        newFilter = {}
                    }

                    newFilter[key] = newValue;
                }
            }
        }
        return newFilter;
    }

    getMedicalServicesMock() {
        return axios.get('assets/features/data/medicalServices.json').then(res => res.data);
    }

    getCountMedicalServicesMock() {
        return axios.get('assets/features/data/medicalServicesCount.json').then(res => {
            return res.data.count
        });
    }

    async deleteMedicalService(id) {
        return await this.delete(`/medical-services/${id}`, true);
    }

    async editMedicalService(medicalService) {
        const data = { name: medicalService.name, state: medicalService.state };
        return await this.patch(`/medical-services/${medicalService.id}`, data, true);
    }

    async createMedicalService(medicalService) {
        const data = { name: medicalService.name, state: medicalService.state };
        return await this.post(`/medical-services`, data, true);
    }
}