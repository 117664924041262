import React, { useState, useEffect, useRef } from 'react';
import { 
    Route,
    Redirect
 } from 'react-router-dom';
import { useSelector } from 'react-redux';
import App from './App';
import Auth from './Auth';
import SignIn from './features/signin/SignIn';
import { AuthService } from './service/AuthService';
import { selectUser } from './features/signin/signInSlice';

export const AuthOrAdmin = props => {
    const user = useSelector(selectUser);
    // const [user, setUser] = useState({name: ''});

    // useEffect(() => {
    //     const authService = new AuthService();
    //     authService.login().then(data => {
    //         console.log("...", data)
    //         setUser(data.user)
    //     });
    // }, []);

    if (user) {
        return (<App>
            <Redirect
                to={{
                    pathname: "/home",
                }}
            />
            {props.children}
        </App>)
    } else {
        return (
            <Auth>
                <Route path="/" component={SignIn} /> 
                <Redirect
                    to={{
                        pathname: "/",
                    }}
                />
            </Auth>
        )
    }
};