import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { 
    selectLoading, 
    selectResidenceList,
    selectProductList, 
    selectProductListCount, 
    getProductsAsync, 
    getProductsCountAsync, 
    deleteProductAsync, 
    editProductAsync,
    createProductAsync,
    initialLoadAsync,
} from './productSlice';

const ProductsView = () => {

    let emptyProduct = {
        id: null,
        code: "",
        name: "",
        description: "",
        residenceId: 1
    }

    const defaultGridParameters = {page: 0, first: 0, rows: 10, sortField: 'id', sortOrder: 1, filters: {}}
    
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);

    const [gridParameters, setGridParameters] = useState(defaultGridParameters);

    const toast = useRef(null);
    const dt = useRef(null);

    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);
    const residenceList = useSelector(selectResidenceList);
    const productList = useSelector(selectProductList);
    const productListCount = useSelector(selectProductListCount);

    useEffect(() => {
        dispatch(initialLoadAsync(defaultGridParameters))
    }, []);

    const onSort = (e) => {
        let filters = {...gridParameters.filters};
        const parameters = {
            ...e,
            page: 0,
            first: 0,
            filters
        };
        setGridParameters(parameters)
        dispatch(getProductsAsync(parameters))
        dispatch(getProductsCountAsync(parameters))
    }

    const onFilter = (e) => {
        let filters = {...gridParameters.filters};
        const parameters = {
            ...e,
            page: 0,
            first: 0,
            filters
        };
        setGridParameters(parameters)
        dispatch(getProductsAsync(parameters))
        dispatch(getProductsCountAsync(parameters))
    }
    
    const onPage = (e) => {
        let filters = {...gridParameters.filters};
        const parameters = {
            ...e,
            filters
        };
        setGridParameters(parameters)
        dispatch(getProductsAsync(parameters))
    }

    const openNew = () => {
        setProduct(emptyProduct);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setProductDialog(false);
    }

    const hideProductDialog = () => {
        setProductDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const saveProduct = () => {
        if (product.id) {
            dispatch(editProductAsync({product, gridParameters}));
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Produto atualizado', life: 3000 });
        } else {
            dispatch(createProductAsync({product, gridParameters}));
            setGridParameters({...gridParameters, page: 0, first: 0});
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Produto criado', life: 3000 });
        }
        setProductDialog(false);
        setProduct(emptyProduct);
    }

    const editProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    }

    const deleteProduct = () => {
        setDeleteProductDialog(false);
        setProduct(emptyProduct);
        dispatch(deleteProductAsync({id: product.id, gridParameters}));
        setGridParameters({...gridParameters, page: 0, first: 0});
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Produto excluído', life: 3000 });
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        // setDeleteProductsDialog(true);
    }

    const onInputChangeProduct = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;
        setProduct(_product);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Novo produto" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const residenceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Residência</span>
                {residenceList.find(it => it.id == rowData.residenceId).description}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideProductDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const deleteProductDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );

    return (
      <>
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt} value={productList} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" 
                        paginator 
                        sortField={gridParameters.sortField}
                        sortOrder={gridParameters.sortOrder}
                        rows={gridParameters.rows} 
                        rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
                        emptyMessage="Sem resultados." 
                        responsiveLayout="scroll"
                        totalRecords={productListCount} 
                        loading={loading}
                        lazy={true}
                        first={gridParameters.first}
                        onSort={(e) => onSort(e)}
                        onFilter={(e) => onFilter(e)}
                        onPage={(e) => onPage(e)}
                        >
                        <Column field="code" header="Código" sortable headerStyle={{ width: '1em', minWidth: '10rem' }}></Column>
                        <Column field="name" header="Nome" sortable headerStyle={{ width: '1em', minWidth: '10rem' }}></Column>
                        <Column field="description" header="Descrição" sortable headerStyle={{ width: '1em', minWidth: '10rem' }}></Column>
                        <Column field="residenceId" header="Residência" body={residenceBodyTemplate} headerStyle={{ width: '1em', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate} style={{ width: '15%', minWidth: '10rem' }}></Column>
                    </DataTable>

                    {/* Editar Produto */}
                    <Dialog visible={productDialog} style={{ width: '450px' }} header={`${product.id ? 'Editar' : 'Criar'} produto`} modal className="p-fluid" footer={productDialogFooter} onHide={hideProductDialog}>

                        <div className="field">
                            <label htmlFor="code">Código</label>
                            <InputText id="code" value={product.code} onChange={(e) => onInputChangeProduct(e, 'code')} required />
                        </div>

                        <div className="field">
                            <label htmlFor="name">Nome</label>
                            <InputText id="name" value={product.name} onChange={(e) => onInputChangeProduct(e, 'name')} required />
                        </div>

                        <div className="field">
                            <label htmlFor="description">Descrição</label>
                            <InputTextarea id="description" value={product.description} onChange={(e) => onInputChangeProduct(e, 'description')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="residenceId">Residência</label>
                            <Dropdown id="residenceId" value={product.residenceId} onChange={(e) => onInputChangeProduct(e, 'residenceId')} options={residenceList} optionLabel="description" optionValue="id" placeholder="Selecione" />
                        </div>

                    </Dialog>
                    {/* FIM - Editar Produto */}

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirmar exclusão" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span>Você deseja realmente excluir: <b>{product.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
      </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ProductsView, comparisonFn);