import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TestService } from '../../service/TestService';

const testService = new TestService();

const initialState = {
  testList: [],
  questionList: [],
  questionListCount: 0,
  offset: 0,
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getQuestionsAsync = createAsyncThunk(
  'questionList',
  async ({offset = 0, first = 0, rows = 10, sortField = 'id', sortOrder = 1, filters = {}}) => {
    console.log('limit', rows)
    console.log('offset', offset)
    console.log('skip', first)
    return await testService.getQuestions(offset, first, rows, sortField, sortOrder, filters)
  }
  
);

export const getQuestionsCountAsync = createAsyncThunk(
  'questionListCount',
  async ({filters = {}}) => await testService.getCountQuestions(filters),
);

export const getTestsAsync = createAsyncThunk(
  'testList',
  async () => await testService.getTests(),
);

export const deleteQuestionAsync = createAsyncThunk(
  'deleteQuestion',
  async ({id, gridParameters}) => {
    await testService.deleteQuestion(id)
    const count = await testService.getCountQuestions(gridParameters.filters);
    const list = await testService.getQuestions(0, 0, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const deleteQuestionsAsync = createAsyncThunk(
  'deleteQuestions',
  async ({ids, gridParameters}) => {
    await testService.deleteQuestions(ids);
    const count = await testService.getCountQuestions(gridParameters.filters);
    const list = await testService.getQuestions(0, 0, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const editQuestionAsync = createAsyncThunk(
  'editQuestion',
  async ({question, gridParameters}) => {
    await testService.editQuestion(question);
    const count = await testService.getCountQuestions(gridParameters.filters);
    const list = await testService.getQuestions(gridParameters.offset, gridParameters.first, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const editChoiceAsync = createAsyncThunk(
  'editChoice',
  async ({choice, gridParameters}) => {
    await testService.editChoice(choice)
    const count = await testService.getCountQuestions(gridParameters.filters);
    const list = await testService.getQuestions(gridParameters.offset, gridParameters.first, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const testSlice = createSlice({
  name: 'testSlice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getTestsAsync.fulfilled, (state, action) => {
        state.testList = action.payload
      })
      .addCase(getQuestionsAsync.pending || getQuestionsCountAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getQuestionsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.questionList = action.payload.data
        state.offset = action.payload.offset
      })
      .addCase(getQuestionsCountAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.questionListCount = action.payload;
      })
      .addCase(
        editQuestionAsync.fulfilled
        , (state, action) => {
        state.status = 'idle';
        state.questionList = action.payload.list.data;
        state.questionListCount = action.payload.count;
      })
      .addCase(editChoiceAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.questionList = action.payload.list.data;
        state.questionListCount = action.payload.count;
      })
      .addCase(deleteQuestionAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.importationList = action.payload.list.data;
        state.importationListCount = action.payload.count;
      })
      .addCase(deleteQuestionsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.importationList = action.payload.list.data;
        state.importationListCount = action.payload.count;
      })
  },
});

export const selectTestList = (state) => state.test.testList;

export const selectQuestionList = (state) => state.test.questionList;

export const selectQuestionListCount = (state) => state.test.questionListCount;

export const selectPage = (state) => state.test.offset;

export const selectLoading = (state) => state.test.status == 'loading';

export default testSlice.reducer;