import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import { 
    selectLoading, 
    selectMedicalServiceList, 
    selectMedicalServiceListCount, 
    getMedicalServicesAsync, 
    getMedicalServicesCountAsync, 
    deleteMedicalServiceAsync, 
    editMedicalServiceAsync,
    createMedicalServiceAsync,
} from './medicalServiceSlice';

const MedicalServiceView = () => {

    let emptyMedicalService = {
        id: null,
        name: "",
        state: ""
    }

    const defaultGridParameters = {page: 0, first: 0, rows: 10, sortField: 'id', sortOrder: 1, filters: {}}
    
    const [deleteMedicalServiceDialog, setDeleteMedicalServiceDialog] = useState(false);
    
    const [medicalServiceDialog, setMedicalServiceDialog] = useState(false);
    const [medicalService, setMedicalService] = useState(emptyMedicalService);
    const [selectedMedicalServices, setSelectedMedicalServices] = useState(null);

    const [gridParameters, setGridParameters] = useState(defaultGridParameters);

    const toast = useRef(null);
    const dt = useRef(null);

    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);
    const medicalServiceList = useSelector(selectMedicalServiceList);
    const medicalServiceListCount = useSelector(selectMedicalServiceListCount);

    useEffect(() => {
        initialLoadDataGrid();
    }, []);

    const initialLoadDataGrid = () => {
        dispatch(getMedicalServicesCountAsync(defaultGridParameters))
        dispatch(getMedicalServicesAsync(defaultGridParameters))
    }

    const loadDataGrid = () => {
        dispatch(getMedicalServicesCountAsync({filters: {}}))
        dispatch(getMedicalServicesAsync({page: 0, first: 0, rows: 10}))
    }
    
    const onSort = (e) => {
        let filters = {...gridParameters.filters};
        const parameters = {
            ...e,
            page: 0,
            first: 0,
            filters
        };
        setGridParameters(parameters)
        dispatch(getMedicalServicesAsync(parameters))
        dispatch(getMedicalServicesCountAsync(parameters))
    }

    const onFilter = (e) => {
        let filters = {...gridParameters.filters};
        const parameters = {
            ...e,
            page: 0,
            first: 0,
            filters
        };
        setGridParameters(parameters)
        dispatch(getMedicalServicesAsync(parameters))
        dispatch(getMedicalServicesCountAsync(parameters))
    }
    
    const onPage = (e) => {
        let filters = {...gridParameters.filters};
        const parameters = {
            ...e,
            filters
        };
        setGridParameters(parameters)
        dispatch(getMedicalServicesAsync(parameters))
    }

    const openNew = () => {
        setMedicalService(emptyMedicalService);
        setMedicalServiceDialog(true);
    }

    const hideMedicalServiceDialog = () => {
        setMedicalServiceDialog(false);
    }

    const hideDeleteMedicalServiceDialog = () => {
        setDeleteMedicalServiceDialog(false);
    }
    
    const saveMedicalService = () => {
        if (medicalService.id) {
            dispatch(editMedicalServiceAsync({medicalService, gridParameters}));
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Serviço atualizado', life: 3000 });
        } else {
            dispatch(createMedicalServiceAsync({medicalService, gridParameters}));
            setGridParameters({...gridParameters, page: 0, first: 0});
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Serviço criado', life: 3000 });
        }
        setMedicalServiceDialog(false);
        setMedicalService(emptyMedicalService);
    }

    const editMedicalService = (medicalService) => {
        setMedicalService({ ...medicalService });
        setMedicalServiceDialog(true);
    }

    const confirmDeleteMedicalService = (medicalService) => {
        setMedicalService(medicalService);
        setDeleteMedicalServiceDialog(true);
    }

    const deleteMedicalService = () => {
        setDeleteMedicalServiceDialog(false);
        setMedicalService(emptyMedicalService);
        dispatch(deleteMedicalServiceAsync({id: medicalService.id, gridParameters}));
        setGridParameters({...gridParameters, page: 0, first: 0});
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Quesão excluída', life: 3000 });
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        // setDeleteMedicalServicesDialog(true);
    }

    const onInputChangeMedicalService = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _medicalService = { ...medicalService };
        _medicalService[`${name}`] = val;
        setMedicalService(_medicalService);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Novo serviço" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editMedicalService(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteMedicalService(rowData)} />
            </div>
        );
    }

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.id}
            </>
        );
    }

    const ufBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">UF</span>
                {rowData.state}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nome</span>
                {rowData.name}
            </>
        );
    }

    const medicalServiceDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideMedicalServiceDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveMedicalService} />
        </>
    );

    const deleteMedicalServiceDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteMedicalServiceDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteMedicalService} />
        </>
    );

    return (
      <>
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={medicalServiceList} selection={selectedMedicalServices} onSelectionChange={(e) => setSelectedMedicalServices(e.value)}
                        dataKey="id" 
                        paginator 
                        sortField={gridParameters.sortField}
                        sortOrder={gridParameters.sortOrder}
                        rows={gridParameters.rows} 
                        rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
                        emptyMessage="Sem resultados." 
                        responsiveLayout="scroll"
                        totalRecords={medicalServiceListCount} 
                        loading={loading}
                        lazy={true}
                        first={gridParameters.first}
                        onSort={(e) => onSort(e)}
                        onFilter={(e) => onFilter(e)}
                        onPage={(e) => onPage(e)}
                        >
                        <Column field="id" header="ID" sortable body={idBodyTemplate} headerStyle={{ width: '1em', minWidth: '5rem' }}></Column>
                        <Column field="name" header="Nome" sortable body={nameBodyTemplate} headerStyle={{ width: '1em', minWidth: '10rem' }}></Column>
                        <Column field="state" header="UF"  filterPlaceholder="Buscar por UF" showAddButton={false} showFilterOperator={false} filterMatchMode={FilterMatchMode.EQUALS} filterMatchModeOptions={[FilterMatchMode.EQUALS]} sortable body={ufBodyTemplate} headerStyle={{ width: '1em', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate} style={{ width: '15%', minWidth: '10rem' }}></Column>
                    </DataTable>

                    {/* Editar Serviço */}
                    <Dialog visible={medicalServiceDialog} style={{ width: '450px' }} header="Editar serviço" modal className="p-fluid" footer={medicalServiceDialogFooter} onHide={hideMedicalServiceDialog}>
                        <h5>{medicalService.title}</h5>

                        <div className="field">
                            <label htmlFor="name">Nome</label>
                            <InputText id="name" value={medicalService.name} onChange={(e) => onInputChangeMedicalService(e, 'name')} required />
                        </div>

                        <div className="field">
                            <label htmlFor="state">UF</label>
                            <InputText id="state" value={medicalService.state} onChange={(e) => onInputChangeMedicalService(e, 'state')} required />
                        </div>
                    </Dialog>
                    {/* FIM - Editar Serviço */}

                    <Dialog visible={deleteMedicalServiceDialog} style={{ width: '450px' }} header="Confirmar exclusão" modal footer={deleteMedicalServiceDialogFooter} onHide={hideDeleteMedicalServiceDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {medicalService && <span>Você deseja realmente excluir: <b>{medicalService.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
      </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MedicalServiceView, comparisonFn);