import React  from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { selectUser, logout } from '../src/features/signin/signInSlice';

export const AppTopbar = (props) => {

    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/AE-Logo_AZUL.png' : 'assets/layout/images/AE-Logo_BRANCO.png'} alt="logo"/>
                {/* <span>OXTON</span> */}
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                <li>
                    <div>
                        <span className='block text-500 font-medium mt-3'>{user.displayName}</span>
                    </div>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={() => dispatch(logout())}>
                        <i className="pi pi-power-off"/>
                        <span>Logout</span>
                    </button>
                </li> 
                {/* <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-calendar"/>
                        <span>Events</span>
                    </button>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-cog"/>
                        <span>Settings</span>
                    </button>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-user"/>
                        <span>Profile</span>
                    </button>
                </li> 
                */}
            </ul>
        </div>
    );
}
