import { createRef } from 'react'
import React  from 'react';

export function AuthenticatedLink ({ url, filename, children }) {
  const link = createRef()
  
  const handleAction = async () => {
    if (link.current.href) { return }
  
    const result = await fetch(url, {	
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(process.env.REACT_APP_TOKEN_KEY)}`
      }
    })
    
    const blob = await result.blob()
    const href = window.URL.createObjectURL(blob)
    
    link.current.download = filename
    link.current.href = href
      
    link.current.click()
  }

  return (
    <>
      <a role='button' ref={link} onClick={handleAction}>{children}</a>
    </>
  )
}