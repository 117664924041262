import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserService } from '../../service/UserService';
import { AuthService } from '../../service/AuthService';

const userService = new UserService();
const authService = new AuthService();

const initialState = {
  userList: [],
  userListCount: 0,
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getUsersAsync = createAsyncThunk(
  'userList',
  async ({page = 0, first = 0, rows = 10, sortField = 'id', sortOrder = 1, filters = {}}) => {
    console.log('limit', rows)
    console.log('page', page)
    console.log('skip', first)
    console.log('filters', filters)
    return await userService.getUsers(page, first, rows, sortField, sortOrder, filters)
  }
);

export const loginAsync = createAsyncThunk(
  'login',
  async ({email, password}) => {
    return await authService.login(email, password);
  }
);

export const getUsersCountAsync = createAsyncThunk(
  'userListCount',
  async ({filters = {}}) => await userService.getCountUsers(filters),
);

export const disableUserAsync = createAsyncThunk(
  'disableUser',
  async (id) => await userService.disableUser(id),
);

export const sendWelcomeEmailAsync = createAsyncThunk(
  'sendWelcomeEmail',
  async (email) => await userService.sendWelcomeEmail(email),
);

export const sendPasswordEmailAsync = createAsyncThunk(
  'sendPasswordEmail',
  async (email) => await userService.sendPasswordEmail(email),
);

export const setIsActiveSubscriptionAsync = createAsyncThunk(
  'setIsActiveSubscription',
  async ({id, isActive, gridParameters}, ) => {
    

    console.log('id', id)
    console.log('isActive', isActive)
    console.log('gridParameters', gridParameters)

    await userService.setIsActiveSubscription(id, isActive)
    // const { data } = await userService.getUsers(gridParameters.page, gridParameters.first, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return await userService.getUsers(gridParameters.page, gridParameters.first, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
  },
);

export const setActiveDeviceAsync = createAsyncThunk(
  'setActiveDevice',
  async ({userId, deviceId, active, gridParameters}, ) => {
    await userService.setActiveDevice(userId, deviceId, active);
    return await userService.getUsers(gridParameters.page, gridParameters.first, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
  },
);

export const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAsync.pending || getUsersCountAsync.pending || setIsActiveSubscriptionAsync.pending || setActiveDeviceAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.userList = [...action.payload.data || []].map(d => {
            d['name'] = `${d.firstName} ${d.lastName}`;
            d.date = new Date(d.createdAt);
            d['isVerifiedAccount'] = d.isVerifiedAccount == 1 ? true : false;
            d['residence'] = (d.residences || []).map(it => it.description.split(' - ')[0].trim()).join(', ');
            return d;
        });
      })
      .addCase(setIsActiveSubscriptionAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.userList = [...action.payload.data || []].map(d => {
            d['name'] = `${d.firstName} ${d.lastName}`;
            d.date = new Date(d.createdAt);
            d['isVerifiedAccount'] = d.isVerifiedAccount == 1 ? true : false;
            return d;
        });
      })
      .addCase(setActiveDeviceAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.userList = [...action.payload.data || []].map(d => {
            d['name'] = `${d.firstName} ${d.lastName}`;
            d.date = new Date(d.createdAt);
            d['isVerifiedAccount'] = d.isVerifiedAccount == 1 ? true : false;
            return d;
        });
      })
      .addCase(getUsersCountAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.userListCount = action.payload;
      });
  },
});

export const { increment, decrement, incrementByAmount } = usersSlice.actions;

export const selectUserList = (state) => state.users.userList;



export const selectUserListCount = (state) => state.users.userListCount;

export const selectLoading = (state) => state.users.status == 'loading';

export default usersSlice.reducer;