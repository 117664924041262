import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';

import { 
    selectLoading, 
    selectTestList,
    selectQuestionList, 
    selectQuestionListCount, 
    selectPage, 
    getQuestionsAsync, 
    getQuestionsCountAsync, 
    getTestsAsync,
    deleteQuestionAsync, 
    deleteQuestionsAsync, 
    editQuestionAsync,
    editChoiceAsync, 
} from './testSlice';

const TestsView = () => {

    let emptyChoice = {
        id: null,
        option: '',
        description: '',
        isCorrect: null,
        explanation: '',
        questionId: null,
    };

    let emptyQuestion = {
        order: null,
        id: null,
        description: "",
        title: "",
        image: null,
        isCanceled: false,
        testId: null,
        moduleId: null,
        test: {
          title: "",
          id: null
        },
        module: {
          id: null,
          description: "",
          weightPercentage: 0.0
        },
        choices: []
    }

    const defaultGridParameters = {offset: 0, first: 0, rows: 10, sortField: 'id', sortOrder: 1, filters: {}}
    
    const [choiceDialog, setChoiceDialog] = useState(false);

    const [deleteQuestionDialog, setDeleteQuestionDialog] = useState(false);
    
    const [deleteChoiceDialog, setDeleteChoiceDialog] = useState(false);
    const [deleteQuestionsDialog, setDeleteQuestionsDialog] = useState(false);
    const [choice, setChoice] = useState(emptyChoice);

    const [questionDialog, setQuestionDialog] = useState(false);
    const [question, setQuestion] = useState(emptyQuestion);
    const [selectedQuestions, setSelectedQuestions] = useState(null);

    const [gridParameters, setGridParameters] = useState(defaultGridParameters);

    const [dropdownValue, setDropdownValue] = useState(null);

    const [filters, setFilters] = useState({
        'id': { value: null, matchMode: FilterMatchMode.EQUALS },
        'title': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'description': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'isCanceled': { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);
    const testList = useSelector(selectTestList);
    const questionList = useSelector(selectQuestionList);
    const questionListCount = useSelector(selectQuestionListCount);
    const offset = useSelector(selectPage);

    useEffect(() => {
        // const choiceService = new ChoiceService();
        // choiceService.getChoices().then(data => setChoices(data));

        // const testService = new TestService();
        // testService.getQuestions().then(data => setQuestions(data));

        initialLoadDataGrid();
        dispatch(getTestsAsync());

    }, []);


    // const onGlobalFilterChange = (e) => {
    //     const value = e.target.value;
    //     let _filters = { ...filters };
    //     _filters['global'].value = value;

    //     setFilters(_filters);
    //     setGlobalFilterValue(value);
    // }

    // const initFilters = () => {
    //     setFilters1({
    //         'id': { value: null, matchMode: FilterMatchMode.EQUALS },
    //         'title': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    //         'description': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    //         'isCanceled': { value: null, matchMode: FilterMatchMode.EQUALS }
    //     });
    //     setGlobalFilterValue('');
    // }

    const initialLoadDataGrid = () => {
        dispatch(getQuestionsCountAsync(defaultGridParameters))
        dispatch(getQuestionsAsync(defaultGridParameters))
    }

    const loadDataGrid = () => {
        dispatch(getQuestionsCountAsync({filters: {}}))
        dispatch(getQuestionsAsync({offset: 0, first: 0, rows: 10}))
    }
    
    const onSort = (e) => {
        let filters = {...gridParameters.filters};
        if (dropdownValue) {
            filters = {...gridParameters.filters, testId: { constraints: [{ value: dropdownValue.id, matchMode: FilterMatchMode.EQUALS }], operator: 'and' }};
        }
        const parameters = {
            ...e,
            offset: 0,
            first: 0,
            filters
        };
        setGridParameters(parameters)
        dispatch(getQuestionsAsync(parameters))
        dispatch(getQuestionsCountAsync(parameters))
    }

    const onFilter = (e) => {
        let filters = {...gridParameters.filters};
        if (dropdownValue) {
            filters = {...gridParameters.filters, testId: { constraints: [{ value: dropdownValue.id, matchMode: FilterMatchMode.EQUALS }], operator: 'and' }};
        }
        const parameters = {
            ...e,
            offset: 0,
            first: 0,
            filters
        };
        setGridParameters(parameters)
        dispatch(getQuestionsAsync(parameters))
        dispatch(getQuestionsCountAsync(parameters))
    }
    
    const onPage = (e) => {
        let filters = {...gridParameters.filters};
        if (dropdownValue) {
            filters = {...gridParameters.filters, testId: { constraints: [{ value: dropdownValue.id, matchMode: FilterMatchMode.EQUALS }], operator: 'and' }};
        }
        const parameters = {
            ...e,
            filters
        };
        setGridParameters(parameters)
        dispatch(getQuestionsAsync(parameters))
    }

    const openNew = () => {
        setChoice(emptyChoice);
        setSubmitted(false);
        setChoiceDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setChoiceDialog(false);
    }

    const hideQuestionDialog = () => {
        setSubmitted(false);
        setQuestionDialog(false);
    }

    const hideDeleteChoiceDialog = () => {
        setDeleteChoiceDialog(false);
    }

    const hideDeleteQuestionDialog = () => {
        setDeleteQuestionDialog(false);
    }

    const hideDeleteQuestionsDialog = () => {
        setDeleteQuestionsDialog(false);
    }

    const saveChoice = () => {
        setSubmitted(true);
        console.log('choice', choice);
        dispatch(editChoiceAsync({choice, gridParameters}));
        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Alternativa da questão atualizada', life: 3000 });
        loadDataGrid()
        setChoiceDialog(false);
        setChoice(emptyChoice)
    }

    const saveQuestion = () => {
        setSubmitted(true);
        console.log('question', question);
        dispatch(editQuestionAsync({question, gridParameters}));
        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Questão atualizada', life: 3000 });
        loadDataGrid();
        setQuestionDialog(false);
        setQuestion(emptyQuestion)
    }

    const editChoice = (choice) => {
        setChoice({ ...choice });
        setChoiceDialog(true);
    }

    const editQuestion = (question) => {
        setQuestion({ ...question });
        setQuestionDialog(true);
    }

    const confirmDeleteChoice = (choice) => {
        setChoice(choice);
        setDeleteChoiceDialog(true);
    }

    const confirmDeleteQuestion = (question) => {
        setQuestion(question);
        setDeleteQuestionDialog(true);
    }

    const deleteChoice = () => {
        setDeleteChoiceDialog(false);
        setChoice(emptyChoice);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Alternativa excluída', life: 3000 });
    }

    const deleteQuestion = () => {
        setDeleteQuestionDialog(false);
        setQuestion(emptyQuestion);
        dispatch(deleteQuestionAsync({id: question.id}));
        loadDataGrid();
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Quesão excluída', life: 3000 });
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteQuestionsDialog(true);
    }

    const deleteSelectedQuestions = () => {
        dispatch(deleteQuestionsAsync({ids: selectedQuestions.map(it => it.id)}));
        setDeleteQuestionsDialog(false);
        setSelectedQuestions(null);
        loadDataGrid();
        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Questões excluídas', life: 3000 });
    }

    const onIsCanceledChange = (e) => {
        let _question = { ...question };
        _question['isCanceled'] = !e.value;
        setQuestion(_question);
    }

    const onIsCorrectChange = (e) => {
        let _choice = { ...choice };
        _choice['isCorrect'] = !e.value;
        setChoice(_choice);
    }

    const onInputChangeQuestion = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _question = { ...question };
        _question[`${name}`] = val;
        setQuestion(_question);
    }

    const onInputChangeChoice = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _choice = { ...choice };
        _choice[`${name}`] = val;

        setChoice(_choice);
    }

    const onDropdownChangeTest = (e) => {
        const filters = {...gridParameters.filters, testId: { constraints: [{ value: e.value.id, matchMode: FilterMatchMode.EQUALS }], operator: 'and' }}
        const parameters = {...gridParameters, filters};
        setGridParameters(parameters)
        setDropdownValue(e.value)
        dispatch(getQuestionsCountAsync(parameters))
        dispatch(getQuestionsAsync(parameters))
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-4">
                    <h5>Prova</h5>
                    <Dropdown value={dropdownValue} onChange={(e) => onDropdownChangeTest(e)} options={testList} optionLabel="title" placeholder="Selecione" />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Excluir" icon="pi pi-trash" className="mr-2 p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedQuestions || !selectedQuestions.length} />
                {/* <FileUpload mode="basic" accept="xlsx/*" maxFileSize={1000000} label="Importar" chooseLabel="Importar" className="mr-2 inline-block" uploadHandler={(e) => console.log('uploadHandler', e)} url={`${process.env.REACT_APP_API_URL}/tests/files`}/>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} /> */}
            </React.Fragment>
        )
    }

    const emptyTemplate = () => {
        return (
            <div className="p-d-flex p-ai-center p-dir-col">
                <i className="pi pi-image p-mt-3 p-p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="p-my-5">Drag and Drop Image Here</span>
            </div>
        )
    }

    const actionBodyTemplate = (rowData) => {
        const options = {icon: 'pi pi-fw pi-images', iconOnly: false, className: 'p-button-rounded p-button-primary mt-2'};
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editQuestion(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteQuestion(rowData)} />
                <FileUpload mode="basic" accept="images/*" maxFileSize={1000000} label="Imagem" chooseLabel="Imagem"
                chooseOptions={options}
                uploadOptions={options}
                uploadHandler={(e) => console.log('uploadHandler', e)} 
                onBeforeSend={(e) => {
                    e.xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem(process.env.REACT_APP_TOKEN_KEY)}`);
                    console.log('onBeforeSend', e)
                }} 
                onUpload={(e) => {
                    console.log('onUpload', e)
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Importação iniciada', life: 3000 });
                    loadDataGrid();
                }} 
                onError={(e) => {
                    console.log('onError', e)
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Falha na importação', life: 3000 });
                    loadDataGrid();
                }} 
                url={`${process.env.REACT_APP_API_URL}/questions/image/${rowData.id}`}
            />
            </div>
        );
    }

    const actionBodyTemplateChoice = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editChoice(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteChoice(rowData)} /> */}
            </div>
        );
    }


    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.id}
            </>
        );
    }

    const isCanceledBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Anulada?</span>
                {rowData.isCanceled ? 'Sim' : 'Não'}
            </>
        );
    }

    const testBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prova</span>
                {rowData.test.title}
            </>
        );
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Título</span>
                {rowData.title}
            </>
        );
    }

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descrição</span>
                {rowData.description}
            </>
        );
    }

    const isCorrectBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Gabarito...</span>
                {rowData.isCorrect ? 'Verdadeiro' : 'Falso'}
                {/* <span className={`choice-badge status-${rowData.isCorrect}`}>{rowData.inventoryStatus}</span> */}
            </>
        )
    }

    const imageBodyTemplate = (rowData) => {
        return <>
            {rowData.image ? <img src={`https://academiadeespecialistas-bucket.s3.us-east-2.amazonaws.com/images/${rowData.id}.png`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image} className="shadow-2" width={100} />: <></>}
        </>
    }

    // const header = (
    //     <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
    //         <h5 className="m-0">Gerenciar questões de provas</h5>
    //         <span className="block mt-2 md:mt-0 p-input-icon-left">
    //             <i className="pi pi-search" />
    //             <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
    //         </span>
    //     </div>
    // );

    const choiceDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveChoice} />
        </>
    );

    const questionDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideQuestionDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveQuestion} />
        </>
    );

    const deleteChoiceDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteChoiceDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteChoice} />
        </>
    );

    const deleteQuestionDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteQuestionDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteQuestion} />
        </>
    );

    const deleteQuestionsDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteQuestionsDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedQuestions} />
        </>
    );

    const rowExpansionTemplate = (data) => {
      return (
          <div className="orders-subtable">
              <h5>{data.title} - Alternativas:</h5>
              <DataTable value={data.choices} responsiveLayout="scroll">
                <Column field="option" header="Alternativa" sortable></Column>
                <Column field="description" header="Descrição" sortable></Column>
                <Column field="isCorrect" header="Gabarito" sortable body={isCorrectBodyTemplate}></Column>
                <Column field="explanation" header="Explicação" sortable></Column>
                <Column body={actionBodyTemplateChoice} style={{ width: '15%', minWidth: '10rem' }}></Column>
              </DataTable>
          </div>
      );
    }

    // const renderHeader = () => {
    //     return (
    //         <div className="flex justify-content-end">
    //             <span className="p-input-icon-left">
    //                 <i className="pi pi-search" />
    //                 <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
    //             </span>
    //         </div>
    //     )
    // }

    // const header = renderHeader();

    return (
      <>
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={questionList} selection={selectedQuestions} onSelectionChange={(e) => setSelectedQuestions(e.value)}
                        dataKey="id" 
                        paginator 
                        sortField={gridParameters.sortField}
                        sortOrder={gridParameters.sortOrder}
                        rows={gridParameters.rows} 
                        rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} questões"
                        globalFilter={globalFilter} 
                        emptyMessage="Sem resultados." 
                        // header={header} 
                        responsiveLayout="scroll"
                        rowExpansionTemplate={rowExpansionTemplate}
                        expandedRows={expandedRows} 
                        onRowToggle={(e) => setExpandedRows(e.data)} 
                        totalRecords={questionListCount} 
                        loading={loading}
                        lazy={true}
                        first={gridParameters.first}
                        onSort={(e) => onSort(e)}
                        onFilter={(e) => onFilter(e)}
                        onPage={(e) => onPage(e)}
                        
                        // globalFilterFields={['id', 'title', 'description', 'isCanceled']} header={header}
                        //  filters={filters}
                        //  filterDisplay="row" 
                        >
                        <Column expander style={{ width: '1em' }} />
                        <Column selectionMode="multiple" headerStyle={{ width: '1em'}}></Column>
                        <Column field="id" header="ID" sortable body={idBodyTemplate} headerStyle={{ width: '1em', minWidth: '5rem' }}></Column>
                        {/* <Column field="testId" header="Prova" filterPlaceholder="Buscar por prova" showAddButton={false} showFilterOperator={false} filterMatchMode={FilterMatchMode.CONTAINS} filterMatchModeOptions={[FilterMatchMode.CONTAINS]} sortable body={testBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                        <Column field="order" header="Questão" filterPlaceholder="Buscar por título" showAddButton={false} showFilterOperator={false} filterMatchMode={FilterMatchMode.CONTAINS} filterMatchModeOptions={[FilterMatchMode.CONTAINS]} sortable body={titleBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="description" header="Enunciado" sortable body={descriptionBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="isCanceled" header="Anulada?" sortable body={isCanceledBodyTemplate} headerStyle={{ width: '5%', minWidth: '5rem' }}></Column>
                        <Column header="Image" body={imageBodyTemplate} />
                        <Column body={actionBodyTemplate} style={{ width: '15%', minWidth: '10rem' }}></Column>
                    </DataTable>

                    {/* Editar Questão */}
                    <Dialog visible={questionDialog} style={{ width: '450px' }} header="Editar questão" modal className="p-fluid" footer={questionDialogFooter} onHide={hideQuestionDialog}>
                        {question.image && <img src={`https://academiadeespecialistas-bucket.s3.us-east-2.amazonaws.com/images/${question.id}.png`} alt={question.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                        
                        <h5>{question.title}</h5>

                        <div className="field">
                            <label htmlFor="description">Enunciado</label>
                            <InputTextarea id="description" value={question.description} onChange={(e) => onInputChangeQuestion(e, 'description')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label className="mb-3">Questão anulada?</label>
                            <div className="formgrid grid">
                                <div className="field-radiobutton col-6">
                                    <Checkbox inputId="isCanceled" name="isCanceled" value={question.isCanceled} onChange={onIsCanceledChange} checked={question.isCanceled == true} />
                                    <label htmlFor="isCanceled">{question.isCanceled ? 'Sim' : 'Não'}</label>
                                </div>
                            </div>
                        </div>                        
                    </Dialog>
                    {/* FIM - Editar Questão */}

                    <Dialog visible={deleteQuestionDialog} style={{ width: '450px' }} header="Confirmar exclusão" modal footer={deleteQuestionDialogFooter} onHide={hideDeleteQuestionDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {choice && <span>Você deseja realmente excluir: <b>{question.title}</b>?</span>}
                        </div>
                    </Dialog>

                    {/* Editar Alternativa */}
                    <Dialog visible={choiceDialog} style={{ width: '450px' }} header="Editar alternativa" modal className="p-fluid" footer={choiceDialogFooter} onHide={hideDialog}>
                        <h5>Alternativa {choice.option}</h5>

                        <div className="field">
                            <label htmlFor="description">Descrição</label>
                            <InputTextarea id="description" value={choice.description} onChange={(e) => onInputChangeChoice(e, 'description')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="explanation">Explicação</label>
                            <InputTextarea id="explanation" value={choice.explanation} onChange={(e) => onInputChangeChoice(e, 'explanation')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label className="mb-3">Alternativa correta?</label>
                            <div className="formgrid grid">
                                <div className="field-radiobutton col-6">
                                    <Checkbox inputId="isCorrect" name="isCorrect" value={choice.isCorrect} onChange={onIsCorrectChange} checked={choice.isCorrect == true} />
                                    <label htmlFor="isCorrect">{choice.isCorrect ? 'Sim' : 'Não'}</label>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    {/* FIM - Editar Alternativa */}

                    <Dialog visible={deleteChoiceDialog} style={{ width: '450px' }} header="Confirmar exclusão" modal footer={deleteChoiceDialogFooter} onHide={hideDeleteChoiceDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {choice && <span>Você deseja realmente excluir a alternativa: <b>{choice.option}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteQuestionsDialog} style={{ width: '450px' }} header="Confirmar exclusão" modal footer={deleteQuestionsDialogFooter} onHide={hideDeleteQuestionsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Você tem certeza que deseja excluir as questões selecionadas?</span>}
                        </div>
                    </Dialog>

                    
                </div>
            </div>
        </div>
      </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TestsView, comparisonFn);