import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MedicalServiceService } from '../../service/MedicalServiceService';

const medicalServiceService = new MedicalServiceService();

const initialState = {
  medicalServiceList: [],
  medicalServiceListCount: 0,
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getMedicalServicesAsync = createAsyncThunk(
  'medicalServiceList',
  async ({page = 0, first = 0, rows = 10, sortField = 'id', sortOrder = 1, filters = {}}) => {
    console.log('limit', rows)
    console.log('page', page)
    console.log('skip', first)
    return await medicalServiceService.getMedicalServices(page, first, rows, sortField, sortOrder, filters)
  }
  
);

export const getMedicalServicesCountAsync = createAsyncThunk(
  'medicalServiceListCount',
  async ({filters = {}}) => await medicalServiceService.getCountMedicalServices(filters),
);

export const deleteMedicalServiceAsync = createAsyncThunk(
  'deleteMedicalService',
  async ({id, gridParameters}) => {
    await medicalServiceService.deleteMedicalService(id)
    const count = await medicalServiceService.getCountMedicalServices(gridParameters.filters);
    const list = await medicalServiceService.getMedicalServices(0, 0, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const editMedicalServiceAsync = createAsyncThunk(
  'editMedicalService',
  async ({medicalService, gridParameters}) => {
    await medicalServiceService.editMedicalService(medicalService);
    const count = await medicalServiceService.getCountMedicalServices(gridParameters.filters);
    const list = await medicalServiceService.getMedicalServices(gridParameters.page, gridParameters.first, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const createMedicalServiceAsync = createAsyncThunk(
  'createMedicalService',
  async ({medicalService, gridParameters}) => {
    await medicalServiceService.createMedicalService(medicalService);
    const count = await medicalServiceService.getCountMedicalServices(gridParameters.filters);
    const list = await medicalServiceService.getMedicalServices(0, 0, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const medicalServiceSlice = createSlice({
  name: 'medicalServiceSlice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getMedicalServicesAsync.pending || getMedicalServicesCountAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMedicalServicesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.medicalServiceList = action.payload.data
      })
      .addCase(getMedicalServicesCountAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.medicalServiceListCount = action.payload;
      })
      .addCase(
        createMedicalServiceAsync.fulfilled
        , (state, action) => {
        state.status = 'idle';
        state.medicalServiceList = action.payload.list.data;
        state.medicalServiceListCount = action.payload.count;
      })
      .addCase(
        editMedicalServiceAsync.fulfilled
        , (state, action) => {
        state.status = 'idle';
        state.medicalServiceList = action.payload.list.data;
        state.medicalServiceListCount = action.payload.count;
      })
      .addCase(
        deleteMedicalServiceAsync.fulfilled
        , (state, action) => {
        state.status = 'idle';
        state.medicalServiceList = action.payload.list.data;
        state.medicalServiceListCount = action.payload.count;
      })
  },
});

export const selectMedicalServiceList = (state) => state.medicalService.medicalServiceList;

export const selectMedicalServiceListCount = (state) => state.medicalService.medicalServiceListCount;

export const selectLoading = (state) => state.medicalService.status == 'loading';

export default medicalServiceSlice.reducer;