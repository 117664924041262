import React, { useState, Fragment } from 'react'
import { BrowserRouter,
    Routes,
    Route, useLocation, Switch } from 'react-router-dom';
// import { Router, Route, IndexRoute, Redirect, hashHistory } from 'react-router'

import {AuthOrAdmin} from '../AuthOrAdmin'
import Dashboard from '../components/Dashboard';
import ButtonDemo from '../components/ButtonDemo';
import ChartDemo from '../components/ChartDemo';
import Documentation from '../components/Documentation';
import FileDemo from '../components/FileDemo';
import FloatLabelDemo from '../components/FloatLabelDemo';
import FormLayoutDemo from '../components/FormLayoutDemo';
import InputDemo from '../components/InputDemo';
import ListDemo from '../components/ListDemo';
import MenuDemo from '../components/MenuDemo';
import MessagesDemo from '../components/MessagesDemo';
import MiscDemo from '../components/MiscDemo';
import OverlayDemo from '../components/OverlayDemo';
import MediaDemo from '../components/MediaDemo';
import PanelDemo from '../components/PanelDemo';
import TableDemo from '../components/TableDemo';
import TreeDemo from '../components/TreeDemo';
import InvalidStateDemo from '../components/InvalidStateDemo';
import BlocksDemo from '../components/BlocksDemo';
import IconsDemo from '../components/IconsDemo';

import Crud from '../pages/Crud';
import EmptyPage from '../pages/EmptyPage';
import TimelineDemo from '../pages/TimelineDemo';

import SignIn from '../features/signin/SignIn';
import UsersView  from '../features/user/UsersView';
import ModulesView  from '../features/modules/ModulesView';
import MedicalServicesView  from '../features/medicalService/MedicalServicesView';
import ImportationView  from '../features/importation/ImportationsView';
import TestView  from '../features/test/TestsView';
import ProductsView from '../features/product/ProductsView';

// import { ToastDemo } from '../components/ToastDemo';

// import Dashboard from '../dashboard/dashboard'
// import Campanha from '../campanha/campanha'
// import Questionario from '../questionario/questionario'
// import Base from '../base/base'
// import Usuario from '../usuario/usuario'
// import Empresa from '../empresa/empresa'
// import Recompensa from '../recompensa/recompensa'
// import DadosUsuario from '../usuario/meus_dados/meusDados'
// import Historico from '../historico/historico'
// import consts from "../consts"
// import { Authorization } from "../auth/authorization"
// import ResponderQuestionario from '../responderQuestionario/responderQuestionario'

// const SuperAdmin = Authorization([consts.ROLE_SUPER_ADMIN]);
// const Administradores = Authorization([consts.ROLE_SUPER_ADMIN, consts.ROLE_ADMIN_COMPANY]);
// const TodosPerfis = Authorization([consts.ROLE_SUPER_ADMIN, consts.ROLE_ADMIN_COMPANY, consts.ROLE_USER_COMPANY]);

export default () => {
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const location = useLocation();
    
    return (
        <BrowserRouter>
            <Switch>
                <AuthOrAdmin>
                    {/* <ToastDemo messages={['teste 1', 'teste 2']}> */}
                    <Route exact path="/" render={()=> <AuthOrAdmin/>}/>
                    {/* </ToastDemo> */}

                    <Route path="/home" render={()=> <Dashboard colorMode={layoutColorMode} location={location} />} />
                    <Route path="/users" component={UsersView} />
                    <Route path="/products" component={ProductsView} />
                    <Route path="/modules" component={ModulesView} />
                    <Route path="/medical-services" component={MedicalServicesView} />
                    <Route path="/importations" component={ImportationView} />
                    <Route path="/tests" component={TestView} />
                    <Route path="/formlayout" component={FormLayoutDemo} />
                    <Route path="/input" component={InputDemo} />
                    <Route path="/floatlabel" component={FloatLabelDemo} />
                    <Route path="/invalidstate" component={InvalidStateDemo} />
                    <Route path="/button" component={ButtonDemo} />
                    <Route path="/table" component={TableDemo} />
                    <Route path="/list" component={ListDemo} />
                    <Route path="/tree" component={TreeDemo} />
                    <Route path="/panel" component={PanelDemo} />
                    <Route path="/overlay" component={OverlayDemo} />
                    <Route path="/media" component={MediaDemo} />
                    <Route path="/menu" component={MenuDemo} />
                    <Route path="/messages" component={MessagesDemo} />
                    <Route path="/blocks" component={BlocksDemo} />
                    <Route path="/icons" component={IconsDemo} />
                    <Route path="/file" component={FileDemo} />
                    <Route path="/chart" render={() => <ChartDemo colorMode={layoutColorMode} location={location} />} />
                    <Route path="/misc" component={MiscDemo} />
                    <Route path="/timeline" component={TimelineDemo} />
                    <Route path="/crud" component={Crud} />
                    <Route path="/empty" component={EmptyPage} />
                    <Route path="/documentation" component={Documentation} /> 
                    <Route path="/signin" component={SignIn} /> 
                </AuthOrAdmin>
            </Switch>
            {/* <Route path='/' component={AuthOrAdmin}> */}
                {/* <Route path="/floatlabel">
                    <FloatLabelDemo/>
                </Route> */}
                
                {/* <Route exact path="/" render={()=> <Dashboard colorMode={layoutColorMode} location={location} />}/> */}
                {/* <Route path="/floatlabel" component={FloatLabelDemo} /> */}
                
                
                
                
                {/* <IndexRoute component={<Dashboard colorMode={layoutColorMode} location={location} />} /> */}


                
                {/* <Route path='questionarios'          component={ TodosPerfis(Questionario) } />
                <Route path='campanhas'              component={ TodosPerfis(Campanha)     } />
                <Route path='bases'                  component={ Administradores(Base)     } />
                <Route path='empresas'               component={ SuperAdmin(Empresa)       } />
                <Route path='usuarios'               component={ Administradores(Usuario)  } />
                <Route path='recompensas'            component={ SuperAdmin(Recompensa)    } />
                <Route path='meus-dados'             component={ TodosPerfis(DadosUsuario) } />
                <Route path='responderQuestionarios' component={ ResponderQuestionario     } />
                <Route path='historico'              component={ Administradores(Historico) } />
                <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} />
                <Route path="/floatlabel" component={FloatLabelDemo} /> */}
            {/* </Route> */}
            {/* <Redirect from='*' to='/' /> */}
    </BrowserRouter>
)}