import axios from 'axios';
import { BaseService } from './BaseService';

export class UserService extends BaseService {

    async getUsers(offset = 0, skip = 0, limit = 10, sortField = 'id', sortOrder = 1, filters) {

        filters = {
            ...filters, 
            isAdmin: { operator: 'and', constraints: [{matchMode: 'equals', value: false }] },
            isHidden: { operator: 'and', constraints: [{matchMode: 'equals', value: false }] }
        }

        let newFilter = this.getFilters(filters);
        let order = sortOrder == 1 ? 'asc' : 'desc';
        if (sortField == 'name') {
            sortField = [`firstName ${order}`, `lastName ${order}`]
        } else {
            sortField = `${sortField} ${sortOrder == 1 ? 'asc' : 'desc'}`;
        }
        
        let parameters = {
            "offset": offset,
            "limit": limit,
            "skip": skip,
            "order": sortField,
            "include": [
                {
                    "relation": "residences",
                    "scope": {
                        "order": "id asc"     
                    }
                },
                {
                    "relation": "devices",
                    "scope": {
                        "order": "id desc"     
                    }
                }
            ]
          };

          if (newFilter) {
            parameters["where"] = newFilter;
          }
          let res = await this.get(`/users?filter=${encodeURI(JSON.stringify(parameters))}`, true).then(res => res.data);
        return {
            data: res,
            page: skip
        }
    }

    async getCountUsers(filters) {

        filters = {
            ...filters, 
            isAdmin: { operator: 'and', constraints: [{matchMode: 'equals', value: false }] },
            isHidden: { operator: 'and', constraints: [{matchMode: 'equals', value: false }] }
        }

        let newFilter = this.getFilters(filters);
        let parameters = null;
        let strWhere = ''

        if (newFilter) {
            parameters = JSON.stringify({...newFilter});
        }

        if (parameters) {
            strWhere += `?where=${encodeURI(parameters)}`;
        }
        return this.get(`/users/count${strWhere}`, true).then(res => res.count);
    }

    async getUsersMock() {
        return axios.get('assets/features/data/users.json').then(res => res.data);
    }

    async getCountUsersMock() {
        return axios.get('assets/features/data/usersCount.json').then(res => res.data.count);
    }

    async disableUser(id) {
        console.log('>> disableUser()', id)        
    }

    async sendWelcomeEmail(email) {
        const data = { email };
        return this.post(`/users/request-registration-code`, data, true);
    }

    async sendWelcomeEmailMock(email) {
        console.log('>> sendWelcomeEmail()', email)
        return axios.get('assets/features/data/welcomeEmailResponse.json').then(res => res.data);
    }

    async sendPasswordEmail(email) {
        const data = { email };
        return this.post(`/users/password-recovery`, data, true);
    }

    async sendPasswordEmailMock(email) {
        console.log('>> sendPasswordEmail()', email)
        return axios.get('assets/features/data/passwordEmailResponse.json').then(res => res.data);
    }

    async setIsActiveSubscription(id, is_active) {
        const data = { is_active };
        return this.patch(`/subscriptions/${id}`, data, true);
    }

    async setActiveDevice(userId, deviceId, active) {
        return this.patch(`/users/${userId}/devices/active/${deviceId}/${active}`, {}, true);
    }

    getFilters(filters = {}) {
        let newFilter = null;

        const fromTo = {
            'contains': 'like',
            'equals': 'eq',
            'startsWith': 'inq'
        };

        if (filters) {
            for (let [key, value] of Object.entries(filters)) {
                console.log(`${key}:`, value);
                if (value.constraints[0].value != null) {
                    let newValue = {};

                    let operator = fromTo[value.constraints[0].matchMode];

                    if (operator == 'like') {
                        newValue[operator] = `%${value.constraints[0].value}%`;
                    } else {
                        newValue[operator] = value.constraints[0].value;
                    }

                    if (newFilter == null) {
                        newFilter = {}
                    }

                    // if (key == 'name') {
                    //     newFilter['or'] = [{ 'firstName': newValue.split(' ') }, { 'lastName': newValue.split(' ') }];
                    //     // newFilter['lastName'] = newValue;
                    // } else {
                    //     newFilter[key] = newValue;
                    // }
                    newFilter[key] = newValue;
                }
            }
        }
        
        return newFilter;
    }

}