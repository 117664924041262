import { configureStore } from '@reduxjs/toolkit';
import appReducer from '../app/appSlice';
import signInReducer from '../features/signin/signInSlice';
import usersReducer from '../features/user/userSlice';
import importationReducer from '../features/importation/importationSlice';
import testReducer from '../features/test/testSlice';
import medicalServiceReducer from '../features/medicalService/medicalServiceSlice';
import moduleReducer from '../features/modules/moduleSlice';
import productReducer from '../features/product/productSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    signin: signInReducer,
    users: usersReducer,
    product: productReducer,
    importation: importationReducer,
    medicalService: medicalServiceReducer,
    module: moduleReducer,
    test: testReducer
  },
});
