import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ImportationService } from '../../service/ImportationService';

const importationService = new ImportationService();

const initialState = {
  testList: [],
  importationList: [],
  importationListCount: 0,
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getImportationsAsync = createAsyncThunk(
  'importationList',
  async ({page = 0, first = 0, rows = 10, sortField = 'id', sortOrder = 1, filters = {}}) => {
    console.log('limit', rows)
    console.log('page', page)
    console.log('skip', first)
    return await importationService.getImportations(page, first, rows, sortField, sortOrder, filters)
  }
  
);

export const getImportationsCountAsync = createAsyncThunk(
  'importationListCount',
  async ({filters = {}}) => await importationService.getCountImportations(filters),
);

export const getTestsAsync = createAsyncThunk(
  'testList',
  async () => await importationService.getTests(),
);

export const deleteImportationAsync = createAsyncThunk(
  'deleteImportation',
  async ({id, gridParameters}) => {
    await importationService.deleteImportation(id)
    const count = await importationService.getCountImportations(gridParameters.filters);
    const list = await importationService.getImportations(0, 0, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const deleteImportationsAsync = createAsyncThunk(
  'deleteImportations',
  async ({ids, gridParameters}) => {
    await importationService.deleteImportations(ids);
    const count = await importationService.getCountImportations(gridParameters.filters);
    const list = await importationService.getImportations(0, 0, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const editImportationAsync = createAsyncThunk(
  'editImportation',
  async ({importation, gridParameters}) => {
    await importationService.editImportation(importation);
    const count = await importationService.getCountImportations(gridParameters.filters);
    const list = await importationService.getImportations(gridParameters.page, gridParameters.first, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const importationFinished = createAsyncThunk(
  'importationFinished',
  async ({gridParameters}) => {
    const count = await importationService.getCountImportations(gridParameters.filters);
    const list = await importationService.getImportations(gridParameters.page, gridParameters.first, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const importationSlice = createSlice({
  name: 'importationSlice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    importationStarted: (state, action) => {
      state.status = 'loading'
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getTestsAsync.fulfilled, (state, action) => {
        state.testList = action.payload
      })
      .addCase(getImportationsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getImportationsCountAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getImportationsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        // state.importationList = action.payload.data
        state.importationList = [...action.payload.data || []].map(d => {
            d['errorList'] = [...d.errorList || []].map((it) => ({description: it}))
            d['hasErrors'] = [...d.errorList || []].length > 0
            return d;
        });
      })
      .addCase(getImportationsCountAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.importationListCount = action.payload;
      })
      .addCase(
        editImportationAsync.fulfilled
        , (state, action) => {
        state.status = 'idle';
        state.importationList = action.payload.list.data;
        state.importationListCount = action.payload.count;
      })
      .addCase(deleteImportationAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.importationList = action.payload.list.data;
        state.importationListCount = action.payload.count;
      })
      .addCase(deleteImportationsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.importationList = action.payload.list.data;
        state.importationListCount = action.payload.count;
      })
      .addCase(
        importationFinished.fulfilled
        , (state, action) => {
        state.status = 'idle';
        state.importationList = action.payload.list.data;
        state.importationListCount = action.payload.count;
      })
  },
});

export const { importationStarted } = importationSlice.actions;

export const selectImportationList = (state) => state.importation.importationList;

export const selectImportationListCount = (state) => state.importation.importationListCount;

export const selectLoading = (state) => state.importation.status == 'loading';

export default importationSlice.reducer;