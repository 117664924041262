import axios from 'axios';

export const setupAxiosInterceptors = (store) => {
  axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && [401, 403].includes(error.response.status)) {
          store.dispatch({ type: 'signinSlice/logout' });
          return error.response;
        }
        return Promise.reject(error);
      }
  );
};