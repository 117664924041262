import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {InputText} from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { signinAsync, selectMessage } from './signInSlice';

const SignIn = () => {

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    const dispatch = useDispatch();
    const message = useSelector(selectMessage);

    return (
        <div className=' px-24 py-4 md:px-6 lg:px-8 flex align-items-center justify-content-center'>
            <div className="surface-card p-8 shadow-2 border-round w-full lg:w-6">
                <div className="text-center mb-5">
                    <img src="assets/layout/images/AE-Logo_AZUL.png" alt="hyper" height="70" className="mb-3" />
                    <div className="text-900 text-2xl font-medium mb-3">Login</div>
                    {/* <span className="text-600 font-medium line-height-3">Não tem uma conta?</span> */}
                    {/* <a className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Criar conta.</a> */}
                </div>

                <div>
                    <label htmlFor="email1" className="block text-900 font-medium mb-2">Email</label>
                    <InputText id="email1" type="text" className="w-full mb-3" value={email} onChange={(e) => setEmail(e.target.value)}/>

                    <label htmlFor="password1" className="block text-900 font-medium mb-2">Senha</label>
                    <InputText id="password1" type="password" className="w-full mb-3" value={password} onChange={(e) => setPassword(e.target.value)}/>

                    <div className="flex align-items-center justify-content-between mb-6">
                        <div className="flex align-items-center">
                            {/* <Checkbox inputId="rememberme1" binary className="mr-2" onChange={e => setChecked(e.checked)} checked={checked} /> */}
                            {/* <label htmlFor="rememberme1">Remember me</label> */}
                            <label className='text-pink-500'>{message}</label>
                        </div>
                        {/* <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Esqueceu a senha?</a> */}
                    </div>
                    
                    <Button label="Entrar" icon="pi pi-user" className="w-full" onClick={() => dispatch(signinAsync({ email, password }))} />
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SignIn, comparisonFn);
