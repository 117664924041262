import axios from 'axios'
import { BaseService } from './BaseService';

export class AuthService extends BaseService {

    async signin(email, password) {
        const data = { email, password };
        return this.post(`/users/login`, data, true).then(res => res.data);
    }

    loginMock() {
        return axios.get('assets/demo/data/login.json')
            .then(res => res.data.data);
    }
}