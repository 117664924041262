import axios from 'axios'

export class BaseService {

  constructor() { }

  async get(resource = '', withAuthorization = false) {
    let config = {};
    if (withAuthorization) {
      config = this.getConfig();
    }
    return axios.get(`${process.env.REACT_APP_API_URL}${resource}`, config).then(res => res.data);
  }

  async post(resource = '', data = {}, withAuthorization = false) {
    let config = {};
    if (withAuthorization) {
      config = this.getConfig();
    }
    return axios.post(`${process.env.REACT_APP_API_URL}${resource}`, data, config).then(res => res.data);
  }

  async patch(resource = '', data = {}, withAuthorization = false) {
    let config = {};
    if (withAuthorization) {
      config = this.getConfig();
    }
    return axios.patch(`${process.env.REACT_APP_API_URL}${resource}`, data, config).then(res => res.data);
  }

  async delete(resource = '', withAuthorization = false) {
    let config = {};
    if (withAuthorization) {
      config = this.getConfig();
    }
    return axios.delete(`${process.env.REACT_APP_API_URL}${resource}`, config).then(res => res.data);
  }

  getConfig() {
    return {
      headers: { 'Authorization': `Bearer ${localStorage.getItem(process.env.REACT_APP_TOKEN_KEY)}` }
      // headers: { 'Authorization': `Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk1MWMwOGM1MTZhZTM1MmI4OWU0ZDJlMGUxNDA5NmY3MzQ5NDJhODciLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiVGhpYWdvIEZpbGd1ZWlyYSIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS0vQU9oMTRHZ1plV205QmRrRU9lTEVUbGpIbTFUQ3VxOWMzOUdNa1NTbjVNQkx1UT1zOTYtYyIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9hY2FkZW1pYS1lc3BlY2lhbGlzdGFzIiwiYXVkIjoiYWNhZGVtaWEtZXNwZWNpYWxpc3RhcyIsImF1dGhfdGltZSI6MTY2OTc2NTE5MCwidXNlcl9pZCI6IjkiLCJzdWIiOiI5IiwiaWF0IjoxNjY5NzY1MTkwLCJleHAiOjE2Njk3Njg3OTAsImVtYWlsIjoiZmlsZ3VlaXJhLmphdmFAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiYXBwbGUuY29tIjpbIjkiLCIwMDEyNzIuZjc3N2M3N2EwODVlNDRhZTgzNTZjYzc0ZDc2NjUyNDMuMjIyMCJdLCJnb29nbGUuY29tIjpbIjkiLCIxMTMxNzA4MTA5MzA3OTU3OTYxMTUiXSwiZW1haWwiOlsiZmlsZ3VlaXJhLmphdmFAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.q7ZSfxUHoi_a5O_9jFShcPA2KowyOPSSHRplHIlKXO0AmtN_4NCdGnjmmTm-TTvsbGhHB654kRu84t9JrabeSr87o3ZwvDAUUnB-uluZvC1_QfZouxf14nu6UT6z6toR4lAIMhxxqDmChO7uSkWuhwHjdCVeaC8o5dainDnZVoE24MJajWEqQEzhrg6_Hp12FhrZRm3DRGEd5wReaDdmO9AKdrhFirDamHGrtv1dDDe8-U6S7-La0ENhVzpnXQFRPZfNN9S6BkZYi7FAQkGn3CbUDKbAIExRnwU1kAvuHtprRVdLOhTautWkEZBN2fvJsEqoQK2HOuiqkxepveFlIw` }
    }
  }
  
}