import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ModuleService } from '../../service/ModuleService';
import { ResidenceService } from '../../service/ResidenceService';

const moduleService = new ModuleService();
const residenceService = new ResidenceService();

const initialState = {
  residenceList: [],
  moduleList: [],
  moduleListCount: 0,
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getModulesAsync = createAsyncThunk(
  'moduleList',
  async ({page = 0, first = 0, rows = 10, sortField = 'id', sortOrder = 1, filters = {}}) => {
    console.log('limit', rows)
    console.log('page', page)
    console.log('skip', first)
    return await moduleService.getModules(page, first, rows, sortField, sortOrder, filters)
  }  
);

export const getModulesCountAsync = createAsyncThunk(
  'moduleListCount',
  async ({filters = {}}) => await moduleService.getCountModules(filters),
);

export const initialLoadAsync = createAsyncThunk(
  'moduleInitialLoad',
  async ({page = 0, first = 0, rows = 10, sortField = 'id', sortOrder = 1, filters = {}}) => {
    const residenceList = await residenceService.getResidences();
    const count = await moduleService.getCountModules(filters)
    const list = await moduleService.getModules(page, first, rows, sortField, sortOrder, filters);
    return {
      residenceList,
      count,
      list
    }
  },
);

export const deleteModuleAsync = createAsyncThunk(
  'deleteModule',
  async ({id, gridParameters}) => {
    await moduleService.deleteModule(id)
    const count = await moduleService.getCountModules(gridParameters.filters);
    const list = await moduleService.getModules(0, 0, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const editModuleAsync = createAsyncThunk(
  'editModule',
  async ({module, gridParameters}) => {
    await moduleService.editModule(module);
    const count = await moduleService.getCountModules(gridParameters.filters);
    const list = await moduleService.getModules(gridParameters.page, gridParameters.first, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const createModuleAsync = createAsyncThunk(
  'createModule',
  async ({module, gridParameters}) => {
    await moduleService.createModule(module);
    const count = await moduleService.getCountModules(gridParameters.filters);
    const list = await moduleService.getModules(0, 0, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const getResidencesAsync = createAsyncThunk(
  'residenceList',
  async () => await residenceService.getResidences(),
);

export const setModuleResidenceAsync = createAsyncThunk(
  'setModuleResidence',
  async ({moduleId, residenceId, gridParameters}, ) => {
    await moduleService.setModuleResidence(moduleId, residenceId)
    const list = await moduleService.getModules(gridParameters.page, gridParameters.first, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters)
    return {
      list
    }
  },
);

export const moduleSlice = createSlice({
  name: 'moduleSlice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getResidencesAsync.fulfilled, (state, action) => {
        state.residenceList = action.payload
      })
      .addCase(initialLoadAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getModulesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getModulesCountAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        initialLoadAsync.fulfilled
        , (state, action) => {
        state.status = 'idle';
        state.residenceList = action.payload.residenceList;
        state.moduleList = action.payload.list.data;
        state.moduleListCount = action.payload.count;
      })
      .addCase(getModulesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.moduleList = action.payload.data
      })
      .addCase(getModulesCountAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.moduleListCount = action.payload;
      })
      .addCase(
        editModuleAsync.fulfilled
        , (state, action) => {
        state.status = 'idle';
        state.moduleList = action.payload.list.data;
        state.moduleListCount = action.payload.count;
      })
      .addCase(
        createModuleAsync.fulfilled
        , (state, action) => {
        state.status = 'idle';
        state.moduleList = action.payload.list.data;
        state.moduleListCount = action.payload.count;
      })
      .addCase(
        deleteModuleAsync.fulfilled
        , (state, action) => {
        state.status = 'idle';
        state.moduleList = action.payload.list.data;
        state.moduleListCount = action.payload.count;
      })
      .addCase(setModuleResidenceAsync.fulfilled, (state, action) => {
        state.moduleList = action.payload.list.data;
      })
  },
});

export const selectResidenceList = (state) => state.module.residenceList;

export const selectModuleList = (state) => state.module.moduleList;

export const selectModuleListCount = (state) => state.module.moduleListCount;

export const selectLoading = (state) => state.module.status == 'loading';

export default moduleSlice.reducer;