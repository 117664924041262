import React from 'react';

export const AppFooter = (props) => {

    return (
        <div className="layout-footer">
            by <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo_oxton.png' : 'assets/layout/images/logo_oxton.png'} alt="Logo" height="20" className="ml-2" />
            {/* by */}
            {/* <span className="font-medium ml-2">Oxton</span> */}
        </div>
    );
}
