import axios from 'axios';
import { BaseService } from './BaseService';

export class ModuleService extends BaseService {

    async getModules(offset = 0, skip = 0, limit = 10, sortField = 'id', sortOrder = 1, filters) {
        let newFilter = this.getFilters(filters);

        let parameters = {
            "offset": offset,
            "limit": limit,
            "skip": skip,
            "order": `${sortField} ${sortOrder == 1 ? 'asc' : 'desc'}`,
            "include": [
                {
                  "relation": "residences",
                  "scope": {
                    "order": "id"     
                  }
                }
              ]
          };

          if (newFilter) {
            parameters["where"] = newFilter;
          }
          let res = await this.get(`/modules?filter=${encodeURI(JSON.stringify(parameters))}`, true);
        return {
            data: res,
            page: skip
        }
    }

    async getCountModules(filters) {
        let newFilter = this.getFilters(filters);
        let parameters = null;
        let strWhere = ''

        if (newFilter) {
            parameters = JSON.stringify({...newFilter});
        }

        if (parameters) {
            strWhere += `?where=${encodeURI(parameters)}`;
        }
        return this.get(`/modules/count${strWhere}`, true).then(res => res.count);
    }

    getFilters(filters = {}) {
        let newFilter = null;

        const fromTo = {
            'contains': 'like',
            'equals': 'eq'
        };

        if (filters) {
            for (const [key, value] of Object.entries(filters)) {
                console.log(`${key}:`, value);
                if (value.constraints[0].value != null) {
                    let newValue = {};

                    let operator = fromTo[value.constraints[0].matchMode];

                    if (operator == 'like') {
                        newValue[operator] = `%${value.constraints[0].value}%`;
                    } else {
                        newValue[operator] = value.constraints[0].value;
                    }

                    if (newFilter == null) {
                        newFilter = {}
                    }

                    newFilter[key] = newValue;
                }
            }
        }
        return newFilter;
    }

    getModulesMock() {
        return axios.get('assets/features/data/modules.json').then(res => res.data);
    }

    getCountModulesMock() {
        return axios.get('assets/features/data/modulesCount.json').then(res => {
            return res.data.count
        });
    }

    async deleteModule(id) {
        return await this.delete(`/modules/${id}`, true);
    }

    async editModule(module) {
        const data = { description: module.description, weightPercentage: module.weightPercentage };
        return await this.patch(`/modules/${module.id}`, data, true);
    }

    async createModule(module) {
        const data = { description: module.description, weightPercentage: module.weightPercentage };
        return await this.post(`/modules`, data, true);
    }

    async setModuleResidence(moduleId, residenceId) {
        const data = { id: residenceId };
        return this.post(`/modules/${moduleId}/link-residence`, data, true);
    }

}