import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ProductService } from '../../service/ProductService';
import { ResidenceService } from '../../service/ResidenceService';

const productService = new ProductService();
const residenceService = new ResidenceService();

const initialState = {
  residenceList: [],
  productList: [],
  productListCount: 0,
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getProductsAsync = createAsyncThunk(
  'productList',
  async ({page = 0, first = 0, rows = 10, sortField = 'id', sortOrder = 1, filters = {}}) => {
    console.log('limit', rows)
    console.log('page', page)
    console.log('skip', first)
    return await productService.getProducts(page, first, rows, sortField, sortOrder, filters)
  }
  
);

export const getProductsCountAsync = createAsyncThunk(
  'productListCount',
  async ({filters = {}}) => await productService.getCountProducts(filters),
);

export const initialLoadAsync = createAsyncThunk(
  'productInitialLoad',
  async ({page = 0, first = 0, rows = 10, sortField = 'id', sortOrder = 1, filters = {}}) => {
    const residenceList = await residenceService.getResidences();
    const count = await productService.getCountProducts(filters);
    const list = await productService.getProducts(page, first, rows, sortField, sortOrder, filters);
    return {
      residenceList,
      count,
      list
    }
  },
);

export const deleteProductAsync = createAsyncThunk(
  'deleteProduct',
  async ({id, gridParameters}) => {
    await productService.deleteProduct(id)
    const count = await productService.getCountProducts(gridParameters.filters);
    const list = await productService.getProducts(0, 0, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const editProductAsync = createAsyncThunk(
  'editProduct',
  async ({product, gridParameters}) => {
    await productService.editProduct(product);
    const count = await productService.getCountProducts(gridParameters.filters);
    const list = await productService.getProducts(gridParameters.page, gridParameters.first, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const createProductAsync = createAsyncThunk(
  'createProduct',
  async ({product, gridParameters}) => {
    await productService.createProduct(product);
    const count = await productService.getCountProducts(gridParameters.filters);
    const list = await productService.getProducts(0, 0, gridParameters.rows, gridParameters.sortField, gridParameters.sortOrder, gridParameters.filters);
    return {
      count,
      list
    }
  },
);

export const getResidencesAsync = createAsyncThunk(
  'residenceList',
  async () => await residenceService.getResidences(),
);

export const productSlice = createSlice({
  name: 'productSlice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getResidencesAsync.fulfilled, (state, action) => {
        state.residenceList = action.payload
      })
      .addCase(initialLoadAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProductsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProductsCountAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        initialLoadAsync.fulfilled
        , (state, action) => {
        state.status = 'idle';
        state.residenceList = action.payload.residenceList;
        state.productList = action.payload.list.data;
        state.productListCount = action.payload.count;
      })
      .addCase(getProductsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.productList = action.payload.data
      })
      .addCase(getProductsCountAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.productListCount = action.payload;
      })
      .addCase(
        createProductAsync.fulfilled
        , (state, action) => {
        state.status = 'idle';
        state.productList = action.payload.list.data;
        state.productListCount = action.payload.count;
      })
      .addCase(
        editProductAsync.fulfilled 
        , (state, action) => {
        state.status = 'idle';
        state.productList = action.payload.list.data;
        state.productListCount = action.payload.count;
      })
      .addCase(
        deleteProductAsync.fulfilled
        , (state, action) => {
        state.status = 'idle';
        state.productList = action.payload.list.data;
        state.productListCount = action.payload.count;
      })
  },
});

export const selectResidenceList = (state) => state.product.residenceList;

export const selectProductList = (state) => state.product.productList;

export const selectProductListCount = (state) => state.product.productListCount;

export const selectLoading = (state) => state.product.status == 'loading';

export default productSlice.reducer;