import axios from 'axios';
import { BaseService } from './BaseService';

export class TestService extends BaseService {

    async getQuestions(offset = 0, skip = 0, limit = 10, sortField = 'id', sortOrder = 1, filters) {
        let newFilter = this.getFilters(filters);

        let parameters = {
            "offset": offset,
            "limit": limit,
            "skip": skip,
            "order": `${sortField} ${sortOrder == 1 ? 'asc' : 'desc'}`,
            "fields": {
              "order": true,
              "id": true,
              "description": true,
              "title": true,
              "image": true,
              "isCanceled": true,
              "testId": true,
              "moduleId": true
            },
            "include": [
              { 
                "relation": "test"
              },
              { 
                "relation": "module"
              },
              {
                "relation": "choices",
                "scope": {
                  "order": "option"     
                }
              }
            ]
          };

          if (newFilter) {
            parameters["where"] = newFilter;
          }
          let res = await this.get(`/questions?filter=${encodeURI(JSON.stringify(parameters))}`, true);
        return {
            data: res,
            page: skip
        }
    }

    async getCountQuestions(filters) {
        let newFilter = this.getFilters(filters);
        let parameters = null;
        let strWhere = ''

        if (newFilter) {
            parameters = JSON.stringify({...newFilter});
        }

        if (parameters) {
            strWhere += `?where=${encodeURI(parameters)}`;
        }
        return this.get(`/questions/count${strWhere}`, true).then(res => res.count);
    }

    getFilters(filters = {}) {
        let newFilter = null;

        const fromTo = {
            'contains': 'like',
            'equals': 'eq'
        };

        if (filters) {
            for (const [key, value] of Object.entries(filters)) {
                console.log(`${key}:`, value);
                if (value.constraints[0].value != null) {
                    let newValue = {};

                    let operator = fromTo[value.constraints[0].matchMode];

                    if (operator == 'like') {
                        newValue[operator] = `%${value.constraints[0].value}%`;
                    } else {
                        newValue[operator] = value.constraints[0].value;
                    }

                    if (newFilter == null) {
                        newFilter = {}
                    }

                    newFilter[key] = newValue;
                }
            }
        }
        return newFilter;
    }

    async getQuestionsMock() {
        return axios.get('assets/features/data/questions.json').then(res => res.data);
    }

    async getCountQuestionsMock() {
        return axios.get('assets/features/data/questionsCount.json').then(res => res.data.count);
    }

    async deleteQuestion(id) {
        const data = { ids: [id] };
        return await this.post(`/questions/delete`, data, true);
    }

    async deleteQuestions(ids) {
        const data = { ids };
        return await this.post(`/questions/delete`, data, true);
    }

    async editQuestion(question) {
        const data = { description: question.description, isCanceled: question.isCanceled };
        return await this.patch(`/questions/${question.id}`, data, true);
    }

    async editChoice(choice) {
        const data = choice;
        return await this.patch(`/choices/${choice.id}`, data, true);
    }

    async getTests() {
        return this.get(`/tests`, true);
    }

}