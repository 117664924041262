import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { setupAxiosInterceptors } from './app/interceptors';
import App from './App';
import Routes from './app/routes'
//import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

setupAxiosInterceptors(store);

const rootElement = document.getElementById('root')
ReactDOM.render(
        <HashRouter>
            <ScrollToTop>
                <Provider store={store}>
                    <Routes/>
                </Provider>
            </ScrollToTop>
        </HashRouter>
    ,
    rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();